import request, { exportFile, importFile } from './index';

// 财务模块前缀 此常量存储财务模块的 URL 前缀
export const FINANCIAL_MODULE_PREFIX = '/route/finance/web/';

export const USER_MODULE_PREFIX = '/route/user/web/'; // 用户模块路由的前缀。
export const STATION_MODULE_PREFIX = '/route/station/web/'; // 用户模块路由的前缀。
export const USER_MODULE_UPLOAD_PREFIX = '/upRoute/user/web/'; // 用户模块路由的前缀。

export const FILE_UPLOAD_GATEWAY = '/upRoute/'; // 文件上传网关
export const FINANCIAL_MODULE_FILE_UPLOAD_PREFIX = `${FILE_UPLOAD_GATEWAY}finance/web/`; // 财务模块路由文件上传的前缀。

export const OPERATION_USER_DENY_PREFIX = '/poly/web/user/'; // 运营商黑名单前缀
export const OM_MANAGEMENT_PREFIX = '/route/device/web/'; // 运维管理模块前缀
export const RECONCILIATION_MANAGEMENT_PREFIX = '/route/statistics/web/'; // 对账管理模块前缀
export const POLY_FINANCIAL_PREFIX = '/poly/web/finance/'; // 基础 POLY 财务服务
export const STATION_WEB_PREFIX = '/route/station/web/'; // 电站 WEB 前缀
export const UP_ROUTE_SUPPORT_PREFIX = '/upRoute/support/web/'; // 上传钩子前缀
export const POLY_STATISWORK_PREFIX = '/poly/web/statisWork/'; // 基础 POLY
/** 登录 */
export const login = body => {
  return request({
    url: `/YkcSsoServer/sso/login`,
    data: {
      header: {
        resultCode: '',
        resultDesc: '',
      },
      body,
    },
  });
};

/** 修改密码 */
export const updatePassword = (data = {}) => {
  return request({
    url: '/route/user/web/user/account/changePassword',
    data,
  });
};

/** 获取钉钉网页登录配置 */
export const getDingTalkConfig = (data = {}) => {
  return request({
    url: '/YkcSsoServer/sso/dingTalk/getConfig',
    data,
  });
};

/** 钉钉网页登录接口 */
export const dingTalkLogin = (params = {}) => {
  return request({
    method: 'GET',
    url: '/YkcSsoServer/sso/dingTalk/callback',
    params,
  });
};

/** 查询当前登录用户信息 */
export const getLoginUserInfo = async (data = {}) => {
  try {
    const response = await request({
      url: '/YkcSsoServer/sso/getLoginUserInfo',
      data,
    });
    // 每次获取用户信息成功，都保存到localStorage中
    if (response?.body && typeof response.body === 'object') {
      localStorage.setItem('userId', response.body.userId || '');
      localStorage.accountNo = response.body.account;
      localStorage.accountName = response.body.realName;
    }
    return response;
  } catch (e) {
    console.log('getLoginUserInfo error:', e);
    return null;
  }
};

/** 退出登录 */
export const logout = (data = {}) => {
  return request({
    url: '/YkcSsoServer/sso/logout',
    data,
  });
};

export const baseSkill = {
  /** 获取省市列表 */
  getProvinceAndCityTree: (data = {}) => {
    return request({
      url: `/IopStationAuthServer/district/cityTree`,
      data,
    });
  },
  /** 获取所有城市列表 */
  getcityInfo: body => {
    return request({
      url: `/priceCenterServer/baseSkill/cityInfo`,
      data: { body },
    });
  },
  /** 根据电站模糊查询 */
  queryIdsByStationName: body => {
    return request({
      url: `/priceCenterServer/baseSkill/powerStation/queryIdsByStationName`,
      data: { body },
    });
  },
  /** 根据车队模糊查询 */
  queryIdsByOrgNameAndType: body => {
    return request({
      url: `/priceCenterServer/baseSkill/vehicle/queryIdsByOrgNameAndType`,
      data: { body },
    });
  },
  /** 根据运营商名称查询信息 */
  queryIdsByOperatorName: body => {
    return request({
      url: `/priceCenterServer/baseSkill/operator/queryIdsByOperatorName`,
      data: { body },
    });
  },
  /** 机构导入 */
  importBOrgIds: data => {
    return importFile({
      url: `/priceCenterServer/baseSkill/file/upload/importBOrgIds`,
      data,
    });
  },
  /** 电站导入 */
  importStationIds: data => {
    return importFile({
      url: `/priceCenterServer/baseSkill/file/upload/importStationIds`,
      data,
    });
  },
};

/** 数据字典模块接口服务 */
export const dict = {
  /** 根据key数组获取字典 */
  // eslint-disable-next-line
  getDictionaryByKeys: body => {
    return Promise.resolve({ body: [] });
    // if (!Array.isArray(body) || body.length === 0) {
    //   throw new Error('参数必须是长度大于1的字符串数组');
    // }
    // return request({
    //   url: '/IopStationAuthServer/dict/getDict',
    //   data: { body },
    // });
  },
  /** 获取数据字典列表(分页) */
  dict_Page: data => {
    return request({
      url: '/IopStationAuthServer/dict/page',
      data,
    });
  },
  /** 新增数据字典 */
  dict_Add: body => {
    return request({
      url: '/IopStationAuthServer/dict/add',
      data: { body },
    });
  },
  /** 删除数据字典 */
  dict_Delete: body => {
    return request({
      url: '/IopStationAuthServer/dict/delete',
      data: { body },
    });
  },
  /** 修改数据字典 */
  dict_Update: body => {
    return request({
      url: '/IopStationAuthServer/dict/update',
      data: { body },
    });
  },
};

/** 销售价特殊配置 */
export const sellPriceSpecial = {
  /** 单个停用 */
  stopOne: body => {
    return request({
      url: `/priceCenterServer/sellPriceSpecial/stopOne`,
      data: { body },
    });
  },
  /** 销售价特殊配置即将到期计数 */
  countWillEnd: body => {
    return request({
      url: `/priceCenterServer/sellPriceSpecial/countWillEnd`,
      data: { body },
    });
  },
  /** 销售价特殊配置批量导入 */
  import: data => {
    return importFile({
      url: `/priceCenterServer/sellPriceSpecial/file/upload/importSpecial`,
      data,
    });
  },
  /** 销售价特殊配置详情 */
  detail: body => {
    return request({
      url: `/priceCenterServer/sellPriceSpecial/detail`,
      data: { body },
    });
  },
  /** 销售价特殊配置停用(批量) */
  stop: body => {
    return request({
      url: `/priceCenterServer/sellPriceSpecial/stop`,
      data: { body },
    });
  },
  /** 销售价特殊配置停用(批量id) */
  batchStop: body => {
    return request({
      url: `/priceCenterServer/sellPriceSpecial/batchStop`,
      data: { body },
    });
  },
  /** 销售价特殊配置列表分页查询 */
  queryForPage: data => {
    return request({
      url: `/priceCenterServer/sellPriceSpecial/queryForPage`,
      data,
    });
  },
  /** 销售价特殊配置导出 */
  export: (fileName, body) => {
    return exportFile({
      url: `/priceCenterServer/sellPriceSpecial/export`,
      data: { body },
      fileName,
    });
  },
  /** 销售价特殊配置编辑 */
  edit: body => {
    return request({
      url: `/priceCenterServer/sellPriceSpecial/edit`,
      data: { body },
    });
  },
  /** 新增 */
  add: body => {
    return request({
      url: `/priceCenterServer/sellPriceSpecial/add`,
      data: { body },
    });
  },
};

/** 采购价特殊配置 */
export const purchasePriceSpecial = {
  /** 采购价特殊配置即将到期计数 */
  countWillEnd: body => {
    return request({
      url: `/priceCenterServer/purchasePriceSpecial/countWillEnd`,
      data: { body },
    });
  },
  /** 采购价特殊配置单个停用 */
  stopOne: body => {
    return request({
      url: `/priceCenterServer/purchasePriceSpecial/stopOne`,
      data: { body },
    });
  },
  /** 采购价特殊配置批量导入 */
  import: data => {
    return importFile({
      url: `/priceCenterServer/purchasePriceSpecial/file/upload/importSpecial`,
      data,
    });
  },
  /** 采购价特殊配置新增 */
  add: body => {
    return request({
      url: `/priceCenterServer/purchasePriceSpecial/add`,
      data: { body },
    });
  },
  /** 采购价特殊配置详情 */
  detail: body => {
    return request({
      url: `/priceCenterServer/purchasePriceSpecial/detail`,
      data: { body },
    });
  },
  /** 采购价特殊配置停用(批量) */
  stop: body => {
    return request({
      url: `/priceCenterServer/purchasePriceSpecial/stop`,
      data: { body },
    });
  },
  /** 采购价特殊配置批量停用 */
  batchStop: body => {
    return request({
      url: `/priceCenterServer/purchasePriceSpecial/batchStop`,
      data: { body },
    });
  },
  /** 采购价特殊配置列表分页查询 */
  queryForPage: data => {
    return request({
      url: `/priceCenterServer/purchasePriceSpecial/queryForPage`,
      data,
    });
  },
  /** 采购价特殊配置导出 */
  export: (fileName, body) => {
    return exportFile({
      url: `/priceCenterServer/purchasePriceSpecial/export`,
      data: { body },
      fileName,
    });
  },
  /** 采购价特殊配置编辑保存 */
  edit: body => {
    return request({
      url: `/priceCenterServer/purchasePriceSpecial/edit`,
      data: { body },
    });
  },
};

/** 合同管理 */
export const contract1 = {
  /** 根据机构ID查询关联合同 */
  queryContractByOrgId: body => {
    return request({
      url: `/priceCenterServer/contract/queryContractByOrgId`,
      data: { body },
    });
  },
  /** 根据电站ID查询关联合同 */
  queryContractByStationId: body => {
    return request({
      url: `/priceCenterServer/contract/queryContractByStationId`,
      data: { body },
    });
  },
};

/** 网点标准采购价 */
export const purchasePriceStandard = {
  /** 网点标准采购价即将到期个数 */
  countWillEnd: body => {
    return request({
      url: `/priceCenterServer/purchasePriceStandard/countWillEnd`,
      data: { body },
    });
  },
  /** 网点标准采购价批量导入 */
  import: data => {
    return importFile({
      url: `/priceCenterServer/purchasePriceStandard/file/upload/batchImport`,
      data,
    });
  },
  /** 网点标准采购价编辑 */
  edit: body => {
    return request({
      url: `/priceCenterServer/purchasePriceStandard/edit`,
      data: { body },
    });
  },
  /** 网点标准采购价详情 */
  detail: body => {
    return request({
      url: `/priceCenterServer/purchasePriceStandard/detail`,
      data: { body },
    });
  },
  /** 网点标准采购价导出 */
  export: (fileName, body) => {
    return exportFile({
      url: `/priceCenterServer/purchasePriceStandard/export`,
      data: { body },
      fileName,
    });
  },
  /** 网点标准采购价列表查询 */
  queryForPage: data => {
    return request({
      url: `/priceCenterServer/purchasePriceStandard/queryForPage`,
      data,
    });
  },
};

/** 客户标准销售价 */
export const sellPriceStandard = {
  /** 客户标准销售价即将到期个数 */
  countWillEnd: body => {
    return request({
      url: `/priceCenterServer/sellPriceStandard/countWillEnd`,
      data: { body },
    });
  },
  /** 客户标准销售价导出 */
  export: (fileName, body) => {
    return exportFile({
      url: `/priceCenterServer/sellPriceStandard/export`,
      data: { body },
      fileName,
    });
  },
  /** 客户标准销售价批量导入 */
  importFlatDiscount: data => {
    return importFile({
      url: `/priceCenterServer/sellPriceStandard/file/upload/importFlatDiscount`,
      data,
    });
  },
  /** 客户标准销售价编辑 */
  edit: body => {
    return request({
      url: `/priceCenterServer/sellPriceStandard/edit`,
      data: { body },
    });
  },
  /** 客户标准销售价详情 */
  detail: body => {
    return request({
      url: `/priceCenterServer/sellPriceStandard/detail`,
      data: { body },
    });
  },
  /** 客户标准销售价列表 */
  queryForPage: data => {
    return request({
      url: `/priceCenterServer/sellPriceStandard/queryForPage`,
      data,
    });
  },
  /** 客户标准销售价获取城市id */
  cityInfo: body => {
    return request({
      url: `/priceCenterServer/baseSkill/cityInfo`,
      data: { body },
    });
  },
};

/** 销售价活动 */
export const sellActivity = {
  /** 销售价活动分页查询 */
  queryForPage: data => {
    return request({
      url: `/priceCenterServer/sellActivity/queryForPage`,
      data,
    });
  },

  /** 销售价活动单个停用 */
  stopOne: body => {
    return request({
      url: `/priceCenterServer/sellActivity/stopOne`,
      data: { body },
    });
  },

  /** 销售价活动即将到期计数 */
  countWillEnd: body => {
    return request({
      url: `/priceCenterServer/sellActivity/countWillEnd`,
      data: { body },
    });
  },

  /** 销售价活动批量导入 */
  batchImport: data => {
    return importFile({
      url: `/priceCenterServer/sellActivity/file/upload/batchImport`,
      data,
    });
  },

  /** 销售价活动导出 */
  export: (fileName, body) => {
    return exportFile({
      url: `/priceCenterServer/sellActivity/export`,
      data: { body },
      fileName,
    });
  },

  /** 销售价活动新增 */
  add: body => {
    return request({
      url: `/priceCenterServer/sellActivity/add`,
      data: { body },
    });
  },

  /** 销售价活动编辑 */
  edit: body => {
    return request({
      url: `/priceCenterServer/sellActivity/edit`,
      data: { body },
    });
  },

  /** 销售价活动详情 */
  detail: body => {
    return request({
      url: `/priceCenterServer/sellActivity/detail`,
      data: { body },
    });
  },

  /** 销售价活动批量停用 */
  stop: body => {
    return request({
      url: `/priceCenterServer/sellActivity/stop`,
      data: { body },
    });
  },

  /** 销售价活动批量停用 */
  batchStop: body => {
    return request({
      url: `/priceCenterServer/sellActivity/batchStop`,
      data: { body },
    });
  },
};

/** 采购活动模块接口服务 */
export const purchaseActivity = {
  /** 采购活动分页查询 */
  queryForPage: data => {
    return request({
      url: `/priceCenterServer/purchaseActivity/queryForPage`,
      data,
    });
  },

  /** 采购价活动单个停用 */
  stopOne: body => {
    return request({
      url: `/priceCenterServer/purchaseActivity/stopOne`,
      data: { body },
    });
  },

  /** 采购价活动即将到期计数 */
  countWillEnd: body => {
    return request({
      url: `/priceCenterServer/purchaseActivity/countWillEnd`,
      data: { body },
    });
  },

  /** 采购价活动批量导入 */
  batchImport: data => {
    return importFile({
      url: `/priceCenterServer/purchaseActivity/file/upload/batchImport`,
      data,
    });
  },

  /** 采购价活动导出 */
  export: (fileName, body) => {
    return exportFile({
      url: `/priceCenterServer/purchaseActivity/export`,
      data: { body },
      fileName,
    });
  },

  /** 采购价活动新增 */
  add: body => {
    return request({
      url: `/priceCenterServer/purchaseActivity/add`,
      data: { body },
    });
  },

  /** 采购价活动编辑 */
  edit: body => {
    return request({
      url: `/priceCenterServer/purchaseActivity/edit`,
      data: { body },
    });
  },

  /** 采购价活动详情 */
  detail: body => {
    return request({
      url: `/priceCenterServer/purchaseActivity/detail`,
      data: { body },
    });
  },

  /** 采购价活动批量停用 */
  stop: body => {
    return request({
      url: `/priceCenterServer/purchaseActivity/stop`,
      data: { body },
    });
  },
  /** 采购价活动批量停用（按id） */
  batchStop: body => {
    return request({
      url: `/priceCenterServer/purchaseActivity/batchStop`,
      data: { body },
    });
  },
};

/** 运营管理 */
export const orgMatchPushRule = {
  /** 运营管理停用 */
  stop: body => {
    return request({
      url: `/IopStationAuthServer/orgMatchPushRule/stop`,
      data: { body },
    });
  },

  /** 运营管理分页查询/即将到期 */
  page: data => {
    return request({
      url: `/IopStationAuthServer/orgMatchPushRule/page`,
      data,
    });
  },

  /** 运营管理即将到期计数 */
  countExpire: body => {
    return request({
      url: `/IopStationAuthServer/orgMatchPushRule/countExpire`,
      data: { body },
    });
  },

  /** 运营管理名称查重 */
  checkName: body => {
    return request({
      url: `/IopStationAuthServer/orgMatchPushRule/checkName`,
      data: { body },
    });
  },

  /** 运营管理导出 */
  export: (fileName, body) => {
    return exportFile({
      url: `/IopStationAuthServer/orgMatchPushRule/export`,
      data: { body },
      fileName,
    });
  },

  /** 运营管理批量停用 */
  batchStop: body => {
    return request({
      url: `/IopStationAuthServer/orgMatchPushRule/batchStop`,
      data: { body },
    });
  },

  /** 运营管理批量导入 */
  upload: data => {
    return importFile({
      url: `/IopStationAuthServer/orgMatchPushRule/upload`,
      data,
    });
  },

  /** 运营管理新增 */
  add: body => {
    return request({
      url: `/IopStationAuthServer/orgMatchPushRule/add`,
      data: { body },
    });
  },

  /** 运营管理机构下拉框 */
  getFleets: body => {
    return request({
      url: `/IopStationAuthServer/orgMatchPushRule/getOrg`,
      data: { body },
    });
  },

  /** 运营管理电站树 */
  station_tree: body => {
    return request({
      url: `/IopStationAuthServer/orgMatchPushRule/station/tree`,
      data: { body },
    });
  },

  /** 运营管理站点配置导入 */
  station_upload: data => {
    return importFile({
      url: `/IopStationAuthServer/orgMatchPushRule/station/upload`,
      data,
    });
  },

  /** 运营管理编辑 */
  edit: body => {
    return request({
      url: `/IopStationAuthServer/orgMatchPushRule/edit`,
      data: { body },
    });
  },

  /** 运营管理详情 */
  detail: body => {
    return request({
      url: `/IopStationAuthServer/orgMatchPushRule/detail`,
      data: { body },
    });
  },
};

/** 代理人模块接口服务 */
export const agent = {
  /** 代理人列表查询 */
  list: data => {
    return request({
      url: `/IopStationAuthServer/agent/list`,
      data,
    });
  },
  /** 代理人列表页聚合信息 */
  polymerization: body => {
    return request({
      url: `/IopStationAuthServer/agent/polymerization`,
      data: { body },
    });
  },
  /** 代理人列表导出 */
  export: (fileName, body) => {
    return exportFile({
      url: `/IopStationAuthServer/agent/list/export`,
      data: { body },
      fileName,
    });
  },
  /** 代理人编辑 */
  update: body => {
    return request({
      url: `/IopStationAuthServer/agent/update`,
      data: { body },
    });
  },
};

/** 佣金申请模块接口服务 */
export const commission = {
  /** 佣金申请列表 */
  list: data => {
    return request({
      url: `/IopStationAuthServer/commission/list`,
      data,
    });
  },
  /** 佣金申请列表导出 */
  export: (fileName, body) => {
    return exportFile({
      url: `/IopStationAuthServer/commission/list/export`,
      data: { body },
      fileName,
    });
  },
  /** 佣金申请详情 */
  detail: body => {
    return request({
      url: `/IopStationAuthServer/commission/detail`,
      data: { body },
    });
  },
  /** 佣金申请编辑 */
  update: body => {
    return request({
      url: `/IopStationAuthServer/commission/update`,
      data: { body },
    });
  },
  /** 佣金列表页聚合信息 */
  polymerization: body => {
    return request({
      url: `/IopStationAuthServer/commission/polymerization`,
      data: { body },
    });
  },
};

/** 电站线索模块接口服务 */
export const station = {
  /** 电站线索列表 */
  list: data => {
    return request({
      url: `/IopStationAuthServer/station/list`,
      data,
    });
  },
  /** 电站线索列表导出 */
  export: (fileName, body) => {
    return exportFile({
      url: `/IopStationAuthServer/station/list/export`,
      data: { body },
      fileName,
    });
  },
  /** 电站线索详情 */
  detail: body => {
    return request({
      url: `/IopStationAuthServer/station/detail`,
      data: { body },
    });
  },
  /** 电站线索核实 */
  verify: body => {
    return request({
      url: `/IopStationAuthServer/station/verify`,
      data: { body },
    });
  },
  /** 电站线索跟进 */
  followUp: body => {
    return request({
      url: `/IopStationAuthServer/station/followUp`,
      data: { body },
    });
  },
};

/** 运营商模块接口服务 */
export const operatior = {
  /** 运营商列表 */
  list: data => {
    return request({
      url: `/IopStationAuthServer/operator/list`,
      data,
    });
  },
  /** 运营商列表导出 */
  export: (fileName, body) => {
    return exportFile({
      url: `/IopStationAuthServer/operator/list/export`,
      data: { body },
      fileName,
    });
  },
};

/**
 * 客户司机
 */
export const customerUser = {
  // 客户司机分页
  queryCompanyUserPageList: data => {
    return request({
      url: '/poly/web/user/queryCompanyUserPageList',
      data,
    });
  },
  // C端司机分页
  queryUserPageList: data => {
    return request({
      url: '/poly/web/user/queryUserPageList',
      data,
    });
  },
  // 客户司机新增
  add: data => {
    return request({
      url: '/poly/web/user/createUser',
      data,
    });
  },
  // 客户司机详情
  detail: data => {
    return request({
      url: '/poly/web/user/queryCompanyUserInfo',
      data,
    });
  },
  // 客户司机编辑
  edit: data => {
    return request({
      url: '/poly/web/user/updateUser',
      data,
    });
  },
  // 司机账号删除
  chargeUserDelete: data => {
    return request({
      url: '/route/user/web/chargeUser/delete',
      data,
    });
  },
  // 司机账号 启用停用
  changeUserStatus: data => {
    return request({
      url: '/route/user/web/chargeUser/changeStatus',
      data,
    });
  },
  // 客户司机 密码修改
  updatePwd: data => {
    return request({
      url: '/route/user/web/chargeUser/updatePwd',
      data,
    });
  },
  // 私桩用户账户
  queryPrivateUserPageList: data => {
    return request({
      url: '/poly/web/user/queryPriPileUserPage',
      data,
    });
  },
};

/**
 * 客户机构
 */
export const customerOrg = {
  // 归属机构 下拉框查询条件专用
  queryOrg: data => {
    return request({
      url: '/route/user/web/org/queryOrgTree',
      data,
    });
  },

  // 客户机构分页
  list: data => {
    return request({
      url: '/route/user/web/org/queryList',
      data,
    });
  },
  // 客户机构新增
  add: data => {
    return request({
      url: '/route/user/web/org/add',
      data,
    });
  },
  // 客户机构详情
  detail: data => {
    return request({
      url: '/route/user/web/org/queryById',
      data,
    });
  },
  // 客户机构编辑
  edit: data => {
    return request({
      url: '/route/user/web/org/update',
      data,
    });
  },
  // 内部公司列表查询条件-主机构下拉框
  queryCompanyMainOrgList: data => {
    return request({
      url: '/route/user/web/org/queryCompanyMainOrgList',
      data,
    });
  },
  // 客户机构 所有机构下拉列表
  queryMainOrgWithOutZdlList: data => {
    return request({
      url: '/route/user/web/org/queryMainOrgWithOutZdlList',
      data,
    });
  },
  // 子公司管理列表-所有下拉框
  queryCompanyAllOrgList: data => {
    return request({
      url: '/route/user/web/org/queryCompanyAllOrgList',
      data,
    });
  },
  // 子机构司机名称验重
  checkUserAccountRepeat: data => {
    return request({
      url: '/route/user/web/chargeUser/checkUserAccountRepeat',
      data,
    });
  },

  // 客户机构 主机构下拉列表
  queryMainOrgList: data => {
    return request({
      url: '/route/user/web/org/queryMainOrgList',
      data,
    });
  },
  // 检验机构名与机构代码是否重复 /user/web/org/checkRepeat
  checkOrgRepeat: data => {
    return request({
      url: '/route/user/web/org/checkRepeat',
      data,
    });
  },
  // 客户机构导入
  importOrg: data => {
    return request({
      url: '/upRoute/user/web/org/importOrg',
      headers: {
        'Content-Type': 'multipart/form-data',
      },
      data,
    });
  },
  // 内部公司批量导入
  importInternalOrg: data => {
    return request({
      url: '/upRoute/user/web/org/importCompanyOrg',
      headers: {
        'Content-Type': 'multipart/form-data',
      },
      data,
    });
  },

  // 客户司机批量导入
  importOrgUser: data => {
    return request({
      url: '/upRoute/user/web/chargeUser/importOrgUser',
      headers: {
        'Content-Type': 'multipart/form-data',
      },
      data,
    });
  },
  // 内部司机批量导入
  importCompanyUser: data => {
    return request({
      url: '/upRoute/user/web/chargeUser/importCompanyUser',
      headers: {
        'Content-Type': 'multipart/form-data',
      },
      data,
    });
  },
  // 客户车辆批量导入
  importOrgCar: data => {
    return request({
      url: '/upRoute/user/web/car/importOrgCar',
      headers: {
        'Content-Type': 'multipart/form-data',
      },
      data,
    });
  },
  // 内部车辆批量导入
  importCompanyCar: data => {
    return request({
      url: '/upRoute/user/web/car/importCompanyCar',
      headers: {
        'Content-Type': 'multipart/form-data',
      },
      data,
    });
  },
  // 车辆批量删除
  batchDelCarByIds: data => {
    return request({
      url: '/route/user/web/car/batchDelCarByIds',
      data,
    });
  },
};

/**
 * 客户司机 账户
 */
export const CustomDriverAccounts = {
  // 列表接口
  list: data => {
    return request({
      url: `${FINANCIAL_MODULE_PREFIX}account/manage/customer/driver/customerDriverList`,
      data,
    });
  },
  // 导出接口 -- 列表页
  export: (fileName, body) => {
    return exportFile({
      url: `${FINANCIAL_MODULE_PREFIX}account/manage/customer/driver/customerDriverList/export`,
      data: { body },
      fileName,
    });
  },
  // 导出接口 -- 详情页 流水列表
  exportOfFlowList: (fileName, body) => {
    return exportFile({
      url: `${FINANCIAL_MODULE_PREFIX}account/manage/customer/driver/flowList/export`,
      data: { body },
      fileName,
    });
  },
  // 客户账户 头部信息 -- 详情页
  detailHeaderInfo: data => {
    return request({
      url: `${FINANCIAL_MODULE_PREFIX}account/manage/customer/driver/header`,
      data,
    });
  },
  // 客户账户 流水列表 -- 详情页
  flowList: data => {
    return request({
      url: `${FINANCIAL_MODULE_PREFIX}account/manage/customer/driver/flowList`,
      data,
    });
  },
};

/**
 * 客户车辆
 */
export const customerVehicle = {
  // 客户车辆列表
  list: data => {
    return request({
      url: '/route/user/web/car/queryList',
      data,
    });
  },
  // 客户车辆增加
  add: data => {
    return request({
      url: '/route/user/web/car/add',
      data,
    });
  },
  // 客户车辆编辑
  edit: data => {
    return request({
      url: '/route/user/web/car/update',
      data,
    });
  },
  // 客户车辆详情
  detail: data => {
    return request({
      url: '/route/user/web/car/queryById',
      data,
    });
  },
  // 客户车辆绑定账号
  bindUser: data => {
    return request({
      url: '/route/user/web/car/bindUser',
      data,
    });
  },
  // 获取用户列表
  queryAllUserByOrgId: data => {
    return request({
      url: '/route/user/web/chargeUser/queryAllUserByOrgId',
      data,
    });
  },
  // 车辆验重
  checkPlateNumberByMainOrgId: data => {
    return request({
      url: '/route/user/web/car/checkPlateNumberByMainOrgId',
      data,
    });
  },
};

/**
 * @description C端司机账户
 */
export const cSideDriverAccount = {
  // 列表接口
  list: data => {
    return request({
      url: `${FINANCIAL_MODULE_PREFIX}account/manage/client/driver/list`,
      data,
    });
  },
  // 导出接口 -- 列表页
  export: (fileName, body) => {
    return exportFile({
      url: `${FINANCIAL_MODULE_PREFIX}account/manage/client/driver/list/export`,
      data: { body },
      fileName,
    });
  },
  // 导出接口 -- C端司机账户详情页的流水列表
  exportOfFlowList: (fileName, body) => {
    return exportFile({
      url: `${FINANCIAL_MODULE_PREFIX}account/manage/client/driver/flowList/export`,
      data: { body },
      fileName,
    });
  },
  // C端司机账户详情 - 头部信息
  detailHeaderInfo: data => {
    return request({
      url: `${FINANCIAL_MODULE_PREFIX}account/manage/client/driver/header`,
      data,
    });
  },
  // C端司机账户详情 - 流水列表
  flowList: data => {
    return request({
      url: `${FINANCIAL_MODULE_PREFIX}account/manage/client/driver/flowList`,
      data,
    });
  },
  //   第三方支付账户
  //   third/queryPayRefundRecordForPage
  queryPayRefundRecordForPage: data => {
    return request({
      url: `${FINANCIAL_MODULE_PREFIX}third/queryPayRefundRecordForPage`,
      data,
    });
  },
  // 充值
  recharge: data => {
    return request({
      url: `/route/finance/app/user/account/adminRecharge`,
      data,
    });
  },
  // 退款审核
  auditApplyRefund: data => {
    return request({
      url: '/route/order/web/auditApplyRefund',
      data,
    });
  },
  // 退款列表
  refundList: data => {
    return request({
      url: '/route/order/web/orderUserOfflineRefundList',
      data,
    });
  },
};

/**
 * @description 账户管理 客户账户
 */
export const customerAccount = {
  // 列表接口
  list: data => {
    return request({
      url: `${FINANCIAL_MODULE_PREFIX}account/manage/customer/customerList`,
      data,
    });
  },
  // 导出接口 -- 列表页
  export: (fileName, body) => {
    return exportFile({
      url: `${FINANCIAL_MODULE_PREFIX}account/manage/customer/customerList/export`,
      data: { body },
      fileName,
    });
  },
  // 导出接口 -- 详情页 流水列表
  exportOfFlowList: (fileName, body) => {
    return exportFile({
      url: `${FINANCIAL_MODULE_PREFIX}account/manage/customer/detail/export`,
      data: { body },
      fileName,
    });
  },
  // 客户账户 头部信息 -- 详情页
  detailHeaderInfo: data => {
    return request({
      url: `${FINANCIAL_MODULE_PREFIX}account/manage/customer/header`,
      data,
    });
  },
  // 客户账户 流水列表 -- 详情页
  flowList: data => {
    return request({
      url: `${FINANCIAL_MODULE_PREFIX}account/manage/customer/flowList`,
      data,
    });
  },
  //   分配或扣回
  allocateOrTakeBack: data => {
    return request({
      url: `${FINANCIAL_MODULE_PREFIX}account/manage/customer/batchAllocationOrTakeBack`,
      data,
    });
  },
  //   充值或扣款
  rechargeOrDeduction: data => {
    return request({
      url: `${FINANCIAL_MODULE_PREFIX}account/manage/customer/adjustmentWallet`,
      data,
    });
  }, //   充值或扣款 互联互通账户
  interconnectedRechargeOrDeduction: data => {
    return request({
      url: `${FINANCIAL_MODULE_PREFIX}account/manage/interconnection/adjustmentWallet`,
      data,
    });
  },
  //   导入分配扣回信息
  importDistributionDeduction: data => {
    return request({
      url: `${FINANCIAL_MODULE_FILE_UPLOAD_PREFIX}account/manage/customer/importAllocationOrTakeBack`,
      headers: {
        'Content-Type': 'multipart/form-data',
      },
      data,
    });
  },
  //   选择对象，查询对象下的子机构和用户
  queryUserAndOrgInfo4Finance: data => {
    return request({
      url: `${USER_MODULE_PREFIX}org/queryUserAndOrgInfo`,
      data,
    });
  },
};

/**
 * @description 账户管理 子公司账户 内部客户账户
 */
export const internalCustomerAccounts = {
  // 列表接口
  list: data => {
    return request({
      url: `${FINANCIAL_MODULE_PREFIX}account/manage/customer/innerCustomerList`,
      data,
    });
  },
  // 导出接口 -- 列表页
  export: (fileName, body) => {
    return exportFile({
      url: `${FINANCIAL_MODULE_PREFIX}account/manage/innerCustomerList/export`,
      data: { body },
      fileName,
    });
  },
  // 导出接口 -- 详情页 流水列表
  exportOfFlowList: (fileName, body) => {
    return exportFile({
      url: `${FINANCIAL_MODULE_PREFIX}account/manage/customer/detail/export`,
      data: { body },
      fileName,
    });
  },
  // 客户账户 头部信息 -- 详情页
  detailHeaderInfo: data => {
    return request({
      url: `${FINANCIAL_MODULE_PREFIX}account/manage/customer/header`,
      data,
    });
  },
  // 客户账户 流水列表 -- 详情页
  flowList: data => {
    return request({
      url: `${FINANCIAL_MODULE_PREFIX}account/manage/customer/flowList`,
      data,
    });
  },
};

/**
 * @description 账户管理 子公司账户 内部客户司机账户
 */
export const internalCustomerSubDriverAccounts = {
  // 列表接口
  list: data => {
    return request({
      url: `${FINANCIAL_MODULE_PREFIX}account/manage/customer/driver/innerCustomerDriverList`,
      data,
    });
  },
  // 导出接口 -- 列表页
  export: (fileName, body) => {
    return exportFile({
      url: `${FINANCIAL_MODULE_PREFIX}account/manage/customer/driver/innerCustomerDriverList/export`,
      data: { body },
      fileName,
    });
  },
  // 导出接口 -- 详情页 流水列表
  exportOfFlowList: (fileName, body) => {
    return exportFile({
      url: `${FINANCIAL_MODULE_PREFIX}account/manage/customer/driver/flowList/export`,
      data: { body },
      fileName,
    });
  },
  // 客户账户 头部信息 -- 详情页
  detailHeaderInfo: data => {
    return request({
      url: `${FINANCIAL_MODULE_PREFIX}account/manage/customer/driver/header`,
      data,
    });
  },
  // 客户账户 流水列表 -- 详情页
  flowList: data => {
    return request({
      url: `${FINANCIAL_MODULE_PREFIX}account/manage/customer/driver/flowList`,
      data,
    });
  },
};

/**
 * 子公司
 */
export const internalOrg = {
  // 子公司新增
  add: data => {
    return request({
      url: '/route/user/web/org/add',
      data,
    });
  },
  // 子公司编辑
  update: data => {
    return request({
      url: '/route/user/web/org/update ',
      data,
    });
  },
  // 子公司列表
  list: data => {
    return request({
      url: '/route/user/web/org/queryList',
      data,
    });
  },
  // 子公司详情
  detail: data => {
    return request({
      url: '/route/user/web/org/queryById',
      data,
    });
  },
  // 主机构下拉框
  MainOrgList: data => {
    return request({
      url: '/route/user/web/org/queryById',
      data,
    });
  },
  // 上级公司下拉框
  AllOrgList: data => {
    return request({
      url: '/route/user/web/org/queryById',
      data,
    });
  },
};

/**
 * 子公司司机
 */
export const internalDriver = {
  // 子公司司机列表
  list: data => {
    return request({
      url: '/route/user/web/org/add',
      data,
    });
  },
  // 子公司司机增加
  add: data => {
    return request({
      url: '/route/user/web/org/add',
      data,
    });
  },
  // 子公司司机编辑
  edit: data => {
    return request({
      url: '/route/user/web/org/add',
      data,
    });
  },
  // 子公司司机详情
  detail: data => {
    return request({
      url: '/route/user/web/org/add',
      data,
    });
  },
};

/**
 * 内部车辆
 * carType='3'
 */
export const internalVehicle = {
  // 客户车辆列表
  list: data => {
    return request({
      url: '/route/user/web/car/queryById',
      data,
    });
  },
  // 客户车辆增加
  add: data => {
    return request({
      url: '/route/user/web/car/add',
      data,
    });
  },
  // 客户车辆编辑
  edit: data => {
    return request({
      url: '/route/user/web/car/update',
      data,
    });
  },
  // 客户车辆详情
  detail: data => {
    return request({
      url: '/route/user/web/car/queryById',
      data,
    });
  },
  // 客户车辆绑定账号
  bindUser: data => {
    return request({
      url: '/route/user/web/car/bindUser',
      data,
    });
  },
};

/**
 * 互联互通客户 单独的列表
 */
export const unioneOrg = {
  // 互联互通客户列表
  list: data => {
    return request({
      url: '/route/user/web/org/queryZdlOrgPageList',
      data,
    });
  },
  // 互联互通客户修改
  edit: data => {
    return request({
      url: '/route/user/web/org/zdlOrgUpdate',
      data,
    });
  },
  // 互联互通客户详情
  detail: data => {
    return request({
      url: '/route/user/web/org/queryZdlOrgConfigById',
      data,
    });
  },
  // 互联互通客户新增
  add: data => {
    return request({
      url: '/route/user/web/org/zdlOrgCreate',
      data,
    });
  },
  // 互联互通站点推送详情
  zdlDetail: data => {
    return request({
      url: '/route/user/web/available/station/zdl/detail',
      data,
    });
  },
  // 互联互通站点推送编辑
  zdlEdit: data => {
    return request({
      url: '/route/user/web/available/station/zdl/edit',
      data,
    });
  },
};

/**
 * @description 账户管理 子公司账户 内部客户司机账户
 */
export const interconnectedAccounts = {
  // 列表接口
  list: data => {
    return request({
      url: `${FINANCIAL_MODULE_PREFIX}account/manage/interconnection/list`,
      data,
    });
  },
  // 导出接口 -- 列表页
  export: (fileName, body) => {
    return exportFile({
      url: `${FINANCIAL_MODULE_PREFIX}account/manage/interconnection/list/export`,
      data: { body },
      fileName,
    });
  },
  // 导出接口 -- 详情页 流水列表
  exportOfFlowList: (fileName, body) => {
    return exportFile({
      url: `${FINANCIAL_MODULE_PREFIX}account/manage/interconnection/flowList/export`,
      data: { body },
      fileName,
    });
  },
  // 客户账户 头部信息 -- 详情页
  detailHeaderInfo: data => {
    return request({
      url: `${FINANCIAL_MODULE_PREFIX}account/manage/interconnection/header`,
      data,
    });
  },
  // 客户账户 流水列表 -- 详情页
  flowList: data => {
    return request({
      url: `${FINANCIAL_MODULE_PREFIX}account/manage/interconnection/flowList`,
      data,
    });
  },
};

/**
 * @description 财务管理 发票管理
 */
export const invoiceManagements = {
  // 列表接口
  list: data => {
    return request({
      url: `${FINANCIAL_MODULE_PREFIX}invoice/manage/list`,
      data,
    });
  },
  // 发票详情
  detail: data => {
    return request({
      url: `${FINANCIAL_MODULE_PREFIX}invoice/manage/detail`,
      data,
    });
  },
  // 驳回
  // POST invoice/manage/cancelInvoice
  // 接口ID：65109394
  // 接口地址：https://www.apifox.cn/web/project/2248392/apis/api-65109394
  doReject: data => {
    return request({
      url: `${FINANCIAL_MODULE_PREFIX}invoice/manage/cancelInvoice`,
      data,
    });
  },
  // 退回
  // POST invoice/manage/backInvoice
  // 接口ID：65110319
  // 接口地址：https://www.apifox.cn/web/project/2248392/apis/api-65110319
  doReturn: data => {
    return request({
      url: `${FINANCIAL_MODULE_PREFIX}invoice/manage/backInvoice`,
      data,
    });
  },
  // 开票申请 -- B端
  // B端申请开票
  // POST /omp/poly/web/finance/invoice/invoiceApply
  // 接口ID：71445675
  // 接口地址：https://www.apifox.cn/web/project/2248392/apis/api-71445675
  doBSideApply: data => {
    return request({
      url: `${POLY_FINANCIAL_PREFIX}invoice/invoiceApply`,
      data,
    });
  },
  // 获取B端开票金额 -- B端
  // POST /omp/poly/web/finance/invoice/getApplyAmount
  // 接口ID：71444779
  // 接口地址：https://www.apifox.cn/web/project/2248392/apis/api-71444779
  getBSideApplyAmount: data => {
    return request({
      url: `${POLY_FINANCIAL_PREFIX}invoice/getApplyAmount`,
      data,
    });
  },
  // 导出接口 -- 列表页
  export: (fileName, body) => {
    return exportFile({
      url: `${FINANCIAL_MODULE_PREFIX}account/manage/interconnection/list/export`,
      data: { body },
      fileName,
    });
  },
  // 确认提交开票申请
  confirmInvoice: data => {
    return request({
      url: `${FINANCIAL_MODULE_PREFIX}invoice/manage/confirmInvoice`,
      data,
    });
  },
  // 线下开票
  offlineInvoice: data => {
    return request({
      url: `${FINANCIAL_MODULE_PREFIX}invoice/manage/offlineStatus`,
      data,
    });
  },
  // 重新开票
  againInvoice: data => {
    return request({
      url: `${FINANCIAL_MODULE_PREFIX}invoice/manage/reToInvoice`,
      data,
    });
  },
  // 红冲
  backInvoice: data => {
    return request({
      url: `${FINANCIAL_MODULE_PREFIX}invoice/manage/backInvoice`,
      data,
    });
  },
};
// 互联互通秘钥
export const cipherUnione = {
  // 秘钥列表
  list: data => {
    return request({
      url: '/route/user/web/org/queryInterconnectionSecretList',
      data,
    });
  },
  // 秘钥新增
  add: data => {
    return request({
      url: '/route/user/web/org/interconnectionSecretCreate',
      data,
    });
  },
  // 秘钥删除
  delete: data => {
    return request({
      url: '/route/user/web/org/interconnectionSecretDel',
      data,
    });
  },
  // 秘钥编辑
  edit: data => {
    return request({
      url: '/route/user/web/org/interconnectionSecretUpdate',
      data,
    });
  },
  // 秘钥详情
  detail: data => {
    return request({
      url: '/route/user/web/org/queryInterconnectionSecretById',
      data,
    });
  },
  // 检测重复名称
  checkExtName: data => {
    return request({
      url: '/route/user/web/org/checkExtName',
      data,
    });
  },
  // 互联机构下拉框
  zdlOrgList: data => {
    return request({
      url: '/route/user/web/org/queryZdlOrgList',
      data,
    });
  },
};

/** 充电价格策略 */
export const chargeStrategy = {
  /** 充电价格策略分页查询 */
  queryForPage: data => {
    return request({
      notShowError: false,
      url: `${STATION_WEB_PREFIX}price/chargeStrategy/queryForPage`,
      data,
    });
  },
  /** 充电价格策略详情 */
  detail: data => {
    return request({
      url: `/route/station/web/price/chargeStrategy/detail`,
      data,
    });
  },
  /** 充电价格策略历史 */
  history: data => {
    return request({
      url: `/route/station/web/price/chargeStrategy/history`,
      data,
    });
  },
  /** 充电价格策略编辑 */
  edit: data => {
    return request({
      url: `/route/station/web/price/chargeStrategy/edit`,
      data,
    });
  },
  /** 充电价格策略批量编辑 */
  batchEdit: data => {
    return request({
      url: `/route/station/web/price/chargeStrategy/batchEdit`,
      data,
    });
  },
  /** 充电价格策略详情价格导出 */
  exportPrice: (fileName, body = {}) => {
    return exportFile({
      url: `/route/station/web/price/chargeStrategy/exportPrice`,
      data: { body },
      fileName,
    });
  },
  /** 充电价格策略分页查询导出 */
  export: (fileName, body = {}) => {
    return exportFile({
      url: `/route/station/web/price/chargeStrategy/export`,
      data: { body },
      fileName,
    });
  },
  /** 充电价格模板分页查询(新接口) */
  chargePriceTemplateQuery: data => {
    return request({
      notShowError: false,
      url: `/route/station/web/price/chargePriceTemplate/queryForPage`,
      data,
    });
  },
  /** 充电价格模板编辑(新接口) */
  chargePriceTemplateEdit: data => {
    return request({
      notShowError: false,
      url: `/route/station/web/price/chargePriceTemplate/edit`,
      data,
    });
  },
  /** 充电价格模板新增(新接口) */
  chargePriceTemplateAdd: data => {
    return request({
      notShowError: false,
      url: `/route/station/web/price/chargePriceTemplate/add`,
      data,
    });
  },
  /** 充电价格模板详情(新接口) */
  chargePriceTemplateDetail: data => {
    return request({
      notShowError: false,
      url: `/route/station/web/price/chargePriceTemplate/queryDetail`,
      data,
    });
  },
  /** 充电价格模板查询(所有数据)新接口) */
  chargePriceTemplateQueryAll: data => {
    return request({
      notShowError: false,
      url: `/route/station/web/price/chargePriceTemplate/queryAll`,
      data,
    });
  },
};

/** 占位价格策略 */
export const occupyStrategy = {
  /** 充电价格策略分页查询 */
  queryForPage: data => {
    return request({
      url: `/route/station/web/price/occupyStrategy/queryForPage`,
      data,
    });
  },
  /** 充电价格策略详情客户列表分页查询 */
  queryOrgForPage: data => {
    return request({
      url: `/route/station/web/price/occupyStrategy/queryOrgForPage`,
      data,
    });
  },
  /** 充电价格策略详情 */
  detail: data => {
    return request({
      url: `/route/station/web/price/occupyStrategy/detail`,
      data,
    });
  },
  /** 充电价格策略历史 */
  history: data => {
    return request({
      url: `/route/station/web/price/occupyStrategy/history`,
      data,
    });
  },
  /** 充电价格策略编辑 */
  edit: data => {
    return request({
      url: `/route/station/web/price/occupyStrategy/edit`,
      data,
    });
  },
  /** 充电价格策略批量编辑 */
  batchEdit: data => {
    return request({
      url: `/route/station/web/price/occupyStrategy/batchEdit`,
      data,
    });
  },
  /** 充电价格策略详情价格导出 */
  exportPrice: (fileName, body = {}) => {
    return exportFile({
      url: `/route/station/web/price/occupyStrategy/exportPrice`,
      data: { body },
      fileName,
    });
  },
  /** 充电价格策略分页查询导出 */
  export: (fileName, body = {}) => {
    return exportFile({
      url: `/route/station/web/price/occupyStrategy/export`,
      data: { body },
      fileName,
    });
  },
};

/** 抽成价格策略 */
export const commissionStrategy = {
  /** 充电价格策略分页查询 */
  queryForPage: data => {
    return request({
      url: `/route/station/web/price/commissionStrategy/queryForPage`,
      data,
    });
  },
  /** 充电价格策略详情 */
  detail: data => {
    return request({
      url: `/route/station/web/price/commissionStrategy/detail`,
      data,
    });
  },
  /** 充电价格策略历史 */
  history: data => {
    return request({
      url: `/route/station/web/price/commissionStrategy/history`,
      data,
    });
  },
  /** 充电价格策略编辑 */
  edit: data => {
    return request({
      url: `/route/station/web/price/commissionStrategy/edit`,
      data,
    });
  },
  /** 充电价格策略批量编辑 */
  batchEdit: data => {
    return request({
      url: `/route/station/web/price/commissionStrategy/batchEdit`,
      data,
    });
  },
  /** 充电价格策略详情价格导出 */
  exportPrice: (fileName, body = {}) => {
    return exportFile({
      url: `/route/station/web/price/commissionStrategy/exportPrice`,
      data: { body },
      fileName,
    });
  },
  /** 充电价格策略分页查询导出 */
  export: (fileName, body = {}) => {
    return exportFile({
      url: `/route/station/web/price/commissionStrategy/export`,
      data: { body },
      fileName,
    });
  },
};

/**
 * 大客户定价
 */
export const bigCustomerPric = {
  /** 【分页查询】大客户定价 */
  priceConfigPage: data => {
    return request({
      url: `/poly/web/largeCustomer/price/config/page`,
      data,
    });
  },
  /** 【修改】修改大客户定价 */
  priceConfigUpdate: data => {
    return request({
      url: `/poly/web/largeCustomer/price/config/update`,
      data,
    });
  },
  /* 【查询】根据orgId查询大客户定价详细 */
  priceConfigDetail: data => {
    return request({
      url: `/poly/web/largeCustomer/price/config/detail`,
      data,
    });
  },
};

/** 订单列表 */
export const orderPage = {
  /** 实时订单分页查询 */
  chargingOrderPage: data => {
    return request({
      url: `/route/order/web/chargingOrderPage`,
      data,
    });
  },
  /** 实时订单分页详情 */
  chargingOrderInfo: data => {
    return request({
      url: `/route/order/web/chargingOrderInfo`,
      data,
    });
  },
  /** 实时订单图表数据 */
  queryRealData: data => {
    return request({
      url: `/route/device/web/queryRealData`,
      data,
    });
  },
  /** 历史订单分页查询 */
  historyChargingOrderPage: data => {
    return request({
      url: `/route/order/web/historyChargingOrderPage`,
      data,
    });
  },
  /** 历史订单详情 */
  historyChargingOrderInfo: data => {
    return request({
      url: `/route/order/web/historyChargingOrderInfo`,
      data,
    });
  },
  /** 异常订单分页查询 */
  abnormalChargingOrderPage: data => {
    return request({
      url: `/route/order/web/abnormalChargingOrderPage`,
      data,
    });
  },
  /** 异常订单详情 */
  abnormalChargingOrderInfo: data => {
    return request({
      url: `/route/order/web/abnormalChargingOrderInfo`,
      data,
    });
  },
  /** 占位订单分页查询 */
  queryOccOrderList: data => {
    return request({
      url: `/route/order/web/occ/queryOccOrderList`,
      data,
    });
  },
  /** 占位订单退款操作 */
  occOrderRefund: data => {
    return request({
      url: `/route/order/web/occ/occOrderRefund`,
      data,
    });
  },
  /** 占位订单导出 */
  placeExport: (fileName, body) => {
    return exportFile({
      url: `/route/support/web/sys/download/offlineExport`,
      data: { body },
      fileName,
    });
  },
  /** 异常订单结算 */
  balanceAbnormalOrder: data => {
    return request({
      url: `/route/order/web/abnormalOrder/balanceAbnormalOrder`,
      data,
    });
  },
  // 订单评价分页列表
  orderEvaluateList: data => {
    return request({
      url: '/route/station/web/evaluate/page',
      data,
    });
  },
  // 订单评价详情
  orderEvaluateDetail: data => {
    return request({
      url: '/route/station/web/evaluate/detail',
      data,
    });
  },
  // 订单评价回复
  orderEvaluateReply: data => {
    return request({
      url: '/route/station/web/evaluate/reply',
      data,
    });
  },
  // 切换订单状态
  changeEvaluateDisplay: data => {
    return request({
      url: '/route/station/web/evaluate/display',
      data,
    });
  },
  // 订单关键词列表
  evaluateKeywordsList: data => {
    return request({
      url: '/route/station/web/evaluate/keywords/page',
      data,
    });
  },
  // 删除关键词
  deleteEvaluateKeyword: data => {
    return request({
      url: '/route/station/web/evaluate/keywords/delete',
      data,
    });
  },
  // 关键词导入
  evaluateKeywordsImport: data => {
    return request({
      url: '/upRoute/station/web/evaluate/keywords/import',
      data,
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    });
  },
  // 解单
  closeOrder: data => {
    return request({
      url: '/route/order/web/closeChargeOrder',
      data,
    });
  },
  // 申请退款
  applyOrderRefund: data => {
    return request({
      url: '/route/order/web/applyOrderRefund',
      data,
    });
  },
};

/** 电站列表 */
export const stationPage = {
  /** 直联电站分页查询 */
  dirFindByPage: data => {
    return request({
      url: `/route/station/web/direct/findByPage`,
      data,
    });
  },

  /** 直联电站导出 */
  dirExport: (fileName, body) => {
    return exportFile({
      url: `/route/station/direct/export`,
      data: { body },
      fileName,
    });
  },

  /** 直联电站新增 */
  dirAdd: data => {
    return request({
      url: `/route/station/web/add`,
      data,
    });
  },

  /** 直联电站编辑 */
  dirUpdate: data => {
    return request({
      url: `/route/station/web/update`,
      data,
    });
  },

  /** 直联电站详情 */
  dirDetail: data => {
    return request({
      url: `/route/station/web/detail`,
      data,
    });
  },
  /** 直联电站运营列表 */
  dirOperation: params => {
    return request({
      method: 'GET',
      url: `/route/statistics/web/station/getStationStatData`,
      params,
    });
  },
  /** 直联电站合同列表 */
  dirContract: data => {
    return request({
      url: `/route/station/web/getStationContractInfo`,
      data,
    });
  },
  /** 直联电站启用停用 */
  dirStatusSwitch: data => {
    return request({
      url: `/route/station/web/statusSwitch`,
      data,
    });
  },
  /** 直联电站设置为测试站 */
  setStationTest: data => {
    return request({
      url: `/route/station/web/setStationTest`,
      data,
    });
  },

  /** 直联电站批量导入 */
  dirImportStationInfo: (fileName, body = {}) => {
    return exportFile({
      url: `/route/station/web/importStationInfo`,
      data: { body },
      fileName,
    });
  },

  /** 互联电站分页查询 */
  intFindByPage: data => {
    return request({
      url: `/route/station/web/interconnection/findByPage`,
      data,
    });
  },

  /** 互联电站详情 */
  intDetail: data => {
    return request({
      url: `/route/station/web/detail`,
      data,
    });
  },

  /** 互联电站导出 */
  intExport: (fileName, body) => {
    return exportFile({
      url: `/route/station/interconnection/export`,
      data: { body },
      fileName,
    });
  },

  /** 互联电站启用停用 */
  intStatusSwitch: data => {
    return request({
      url: `/route/station/web/statusSwitch`,
      data,
    });
  },

  /** 站点运营配置分页查询 */
  configFindByPage: data => {
    return request({
      url: `/route/station/operate/config/findByPage`,
      data,
    });
  },
  /** 站点运营配置双枪并充配置 */
  configFindByPageAdd: data => {
    return request({
      url: `/route/station/operate/config/add`,
      data,
    });
  },

  /** findStationPrice */
  findStationPrice: data => {
    return request({
      url: `/route/station/web/findStationPrice`,
      data,
    });
  },

  /** 互联电站同步 */
  synchronize: (params = {}) => {
    return request({
      method: 'GET',
      url: '/route/station/synchronize',
      params,
    });
  },

  /** 修改app可见 */
  updateAppShow: data => {
    return request({
      url: `/route/station/web/updateAppShow`,
      data,
    });
  },
};

/** 电桩列表 */
export const directPage = {
  syncStationInfos: data => {
    return request({
      url: '/route/zdl/plat/syncStationInfos',
      data,
    });
  },
  syncBusinessPolicy: data => {
    return request({
      url: '/route/zdl/plat/syncBusinessPolicy',
      data,
    });
  },
  /** 归属商户列表查询 */
  userOperatorList: data => {
    return request({
      url: `/route/user/web/operator/userOperatorList`,
      data,
    });
  },

  /** 直联电桩分页查询 */
  dirFindByPage: data => {
    return request({
      url: `/route/station/web/pile/direct/findByPage`,
      data,
    });
  },

  /** 校时校价 */
  setTimeAndPrice: data => {
    return request({
      url: `/route/device/web/setTimeAndPrice`,
      data,
    });
  },
  /** 批量删除 */
  batchDelete: data => {
    return request({
      url: `/route/station/web/pile/deleteBatch`,
      data,
    });
  },
  // 发布二维码
  publishQrcode: data => {
    return request({
      url: `/route/device/web/sendMarks`,
      data,
    });
  },

  /** 直联电桩导出 */
  dirExport: (fileName, body) => {
    return exportFile({
      url: `/route/station/pile/direct/export`,
      data: { body },
      fileName,
    });
  },

  /** 直联电桩新增 */
  dirAdd: data => {
    return request({
      url: `/route/station/web/pile/add`,
      data,
    });
  },

  /** 直联电桩编辑 */
  dirUpdate: data => {
    return request({
      url: `/route/station/web/pile/update`,
      data,
    });
  },

  /** 直联电桩详情 */
  dirDetail: data => {
    return request({
      url: `/route/station/web/pile/detail`,
      data,
    });
  },

  /** 直联电桩批量启停 */
  dirStartSwitch: data => {
    return request({
      url: `/route/station/web/pile/startSwitch`,
      data,
    });
  },

  /** 直联电桩删除 */
  dirDelete: data => {
    return request({
      url: `/route/station/web/pile/delete`,
      data,
    });
  },

  /** 直联电桩批量导入 */
  dirImport: data => {
    return request({
      url: `/upRoute/station/web/pile/direct/import`,
      headers: {
        'Content-Type': 'multipart/form-data',
      },
      data,
    });
  },

  /** 互联电桩导出 */
  intExport: (fileName, body) => {
    return exportFile({
      url: `/route/station/pile/interconnection/export`,
      data: { body },
      fileName,
    });
  },

  /** 互联电桩批量启停 */
  intStartSwitch: data => {
    return request({
      url: `/route/station/web/pile/startSwitch`,
      data,
    });
  },

  /** 互联电桩分页查询 */
  intFindByPage: data => {
    return request({
      url: `/route/station/web/pile/interconnection/findByPage`,
      data,
    });
  },
  /** 社区私桩分页查询 */
  privateFindByPage: data => {
    return request({
      url: `/route/station/web/pile/private/findByPage`,
      data,
    });
  },
  /** 社区私桩日志查询 */
  privateLog: data => {
    return request({
      url: `/route/statistics/web/protocol/queryPage`,
      data,
    });
  },
  /** 社区私桩车位号检测查询 */
  checkParkNum: data => {
    return request({
      url: `/route/station/web/pile/verify/parkNum`,
      data,
    });
  },
  /** 社区私桩换桩 */
  changePile: data => {
    return request({
      url: `/poly/web/station/pile/changePrivatePile`,
      data,
    });
  },
  /** 社区私桩查询桩编码 */
  queryPile: params => {
    return request({
      method: 'GET',
      url: `/route/station/web/pile/queryTestPile`,
      params,
    });
  },
  /** 测试电桩迁桩 */
  testPileChange: data => {
    return request({
      url: `/route/station/web/pile/pile/testPileToFormal`,
      data,
    });
  },
};

/** 品牌型号 */
export const brandModelPage = {
  /** 品牌型号分页查询 */
  queryForPage: data => {
    return request({
      url: `/route/station/web/brandModel/brandModelPageList`,
      data,
    });
  },

  /** 模块品牌列表 */
  moduleBrandList: (params = {}) => {
    return request({
      method: 'GET',
      url: '/route/station/web/brandModel/moduleBrandList',
      params,
    });
  },

  /** 品牌列表 */
  brandList: (params = {}) => {
    return request({
      method: 'GET',
      url: '/route/station/web/brandModel/brandList',
      params,
    });
  },

  /** 品牌型号列表 */
  brandModelList: data => {
    return request({
      url: `/route/station/web/brandModel/brandModelList`,
      data,
    });
  },

  /** 品牌型号详情 */
  detail: (params = {}) => {
    return request({
      method: 'GET',
      url: '/route/station/web/brandModel/brandModelDetail',
      params,
    });
  },

  /** 品牌型号新增/编辑 */
  saveOrUpdate: data => {
    return request({
      url: `/route/station/web/brandModel/saveOrUpdate`,
      data,
    });
  },

  /** 品牌型号导出 */
  exportPrice: (fileName, body) => {
    return exportFile({
      url: `/route/station/price/chargeStrategy/exportPrice`,
      data: { body },
      fileName,
    });
  },
};

/** 道闸管理 */
export const barrierPage = {
  /** 道闸管理分页查询 */
  queryForPage: data => {
    return request({
      url: `/route/station/web/barrier/barrierPageList`,
      data,
    });
  },

  /** 道闸管理删除/批量删除 */
  delBarrier: data => {
    return request({
      url: `/route/station/web/barrier/delBarrier`,
      data,
    });
  },

  /** 道闸管理详情 */
  detail: (params = {}) => {
    return request({
      method: 'GET',
      url: '/route/station/web/barrier/getBarrierDetail',
      params,
    });
  },

  /** 道闸管理导出 */
  exportBarrierList: (fileName, body) => {
    return exportFile({
      url: `/route/station/web/barrier/exportBarrierList`,
      data: { body },
      fileName,
    });
  },

  /** 道闸管理导入 */
  importBarrierList: (fileName, body = {}) => {
    return exportFile({
      url: `/route/station/web/barrier/importBarrierList`,
      data: { body },
      fileName,
    });
  },

  /** 道闸管理 归属电站 新增/编辑 */
  findStationList: data => {
    return request({
      url: `/route/station/web/findStationList`,
      data,
    });
  },
  /** 道闸管理 公站私站 */
  findPriOrPubStations: params => {
    return request({
      method: 'GET',
      url: `/route/station/web/findPriOrPubStations`,
      params,
    });
  },

  /** 道闸管理 新增/编辑 */
  saveOrUpdate: data => {
    return request({
      url: `/route/station/web/barrier/saveOrUpdate`,
      data,
    });
  },
};

/** 有序管理 */
export const orderlyPage = {
  /** ****************start 有序 新*************************** */
  /** 有序控制列表 */
  orderlyControlList: data => {
    return request({
      url: `/route/station/web/orderlyCharge/orderlyChargeList`,
      data,
    });
  },
  /** 有序控制保存 */
  orderlyControlSave: data => {
    return request({
      url: `/route/station/web/orderlyCharge/updateOrderlyCharge`,
      data,
    });
  },
  /** 有序控制详情 */
  orderlyControlDetail: data => {
    return request({
      url: `/route/station/web/orderlyCharge/orderlyChargeDetail`,
      data,
    });
  },
  /** 有序统计列表 */
  orderlyControlStatistics: data => {
    return request({
      url: `/route/statistics/web/orderlyChargeStatistics/orderlyStatistics`,
      data,
    });
  },
  /** 有序记录 */
  orderlyRecordList: data => {
    return request({
      url: `/route/statistics/web/orderlyChargeStatistics/orderlyStatisticsRecord`,
      data,
    });
  },
  /** ****************end 有序 新*************************** */

  /** 有序管理分页查询 */
  orderPlatformPageList: data => {
    return request({
      url: `/route/station/web/orderPlatform/orderPlatformPageList`,
      data,
    });
  },

  /** 有序管理新增 */
  saveOrUpdate: data => {
    return request({
      url: `/route/station/web/orderPlatform/saveOrUpdate`,
      data,
    });
  },

  /** 查询电站和终端信息 */
  findStationAndGun: data => {
    return request({
      url: `/route/station/web/findStationAndGun`,
      data,
    });
  },

  /** 有序管理详情 */
  orderPlatformDetail: (params = {}) => {
    return request({
      method: 'GET',
      url: '/route/station/web/orderPlatform/orderPlatformDetail',
      params,
    });
  },
};

/** 数据统计 */
export const dataStatisticsServe = {
  /** 充电趋势 */
  chargingTrendList: data => {
    return request({
      url: `/route/statistics/web/platform/chargingTrend`,
      data,
    });
  },

  /** 商户充电统计明细分页 */
  detailPageList: data => {
    return request({
      url: `/route/statistics/web/operatorStatistics/detailPageList`,
      data,
    });
  },

  /** 平台/商户充电趋势统计明细 */
  trendDetailList: data => {
    return request({
      url: `/route/statistics/web/platform/trendDetailList`,
      data,
    });
  },

  /** 电站充电统计明细 */
  stationChargingList: data => {
    return request({
      url: '/route/statistics/web/station/stationChargingList',
      data,
    });
  },

  // 充值退还明细
  rechargeAndRefundList: data => {
    return request({
      url: `${FINANCIAL_MODULE_PREFIX}account/manage/client/driver/rechargeAndRefundList`,
      data,
    });
  },

  /** 小时统计列表(新接口)☀ */
  queryTrendTimeDetailList: data => {
    return request({
      url: `/route/statistics/web/platform/queryTrendTimeDetailList`,
      data,
    });
  },

  /** 小时统计趋势(新接口)☀ */
  queryChargingTimeTrend: data => {
    return request({
      url: `/route/statistics/web/platform/queryChargingTimeTrend`,
      data,
    });
  },

  /** 流水统计列表(新接口)☀ */
  queryFlowMonthList: data => {
    return request({
      url: `${FINANCIAL_MODULE_PREFIX}account/flow/queryFlowMonthList`,
      data,
    });
  },

  /** 流水统计明细(新接口)☀ */
  queryFlowMonthDetail: data => {
    return request({
      url: `${FINANCIAL_MODULE_PREFIX}account/flow/queryFlowMonthDetail`,
      data,
    });
  },

  /** 电站统计明细列表(新接口)☀ */
  stationChargingDetailList: data => {
    return request({
      url: `/route/statistics/web/station/stationChargingDetailList`,
      data,
    });
  },

  /** 终端统计明细列表(新接口)☀ */
  queryGunChargingList: data => {
    return request({
      url: `/route/statistics/web/station/queryGunChargingList`,
      data,
    });
  },

  /** 商户统计明细列表(新接口)☀ */
  queryOperatorChargingList: data => {
    return request({
      url: `/route/statistics/web/station/queryOperatorChargingList`,
      data,
    });
  },

  /** 充电数据统计趋势☀ 【电站统计，商户统计趋势用此接口】 */
  queryStationChargingTrendList: data => {
    return request({
      url: `/route/statistics/web/station/queryStationChargingTrendList`,
      data,
    });
  },
};

/** 积分商城 */
export const pointsMall = {
  /** 兑换管理-分页列表 */
  page: data => {
    return request({
      url: `/route/marketing/web/mall/order/queryForPage`,
      data,
    });
  },
  /** 兑换管理-详情 */
  detail: data => {
    return request({
      url: `/route/marketing/web/mall/order/detail`,
      data,
    });
  },
  /** 兑换管理-发货 */
  deliver: data => {
    return request({
      url: `/route/marketing/web/mall/order/deliver`,
      data,
    });
  },
  /** 兑换管理-取消 */
  cancel: data => {
    return request({
      url: `/route/marketing/web/mall/order/cancel`,
      data,
    });
  },
  /** 商品管理-分页列表 */
  skuPage: data => {
    return request({
      url: `/route/marketing/web/mall/sku/queryForPage`,
      data,
    });
  },
  /** 商品管理-新增 */
  add: data => {
    return request({
      url: `/route/marketing/web/mall/sku/add`,
      data,
    });
  },
  /** 商品管理-编辑 */
  edit: data => {
    return request({
      url: `/route/marketing/web/mall/sku/edit`,
      data,
    });
  },
  /** 商品管理-定价 */
  price: data => {
    return request({
      url: `/route/marketing/web/mall/sku/price`,
      data,
    });
  },
  /** 商品管理-库存 */
  stock: data => {
    return request({
      url: `/route/marketing/web/mall/sku/stock`,
      data,
    });
  },
  /** 商品管理-上架/下架 */
  status: data => {
    return request({
      url: `/route/marketing/web/mall/sku/status`,
      data,
    });
  },
  /** 商品管理-商品详情 */
  skuDetail: data => {
    return request({
      url: `/route/marketing/web/mall/sku/detail`,
      data,
    });
  },
  /** 商品管理-商品详情-库存分页 */
  queryStockForPage: data => {
    return request({
      url: `/route/marketing/web/mall/sku/queryStockForPage`,
      data,
    });
  },
  /** 商品管理-商品详情-价格分页 */
  queryPriceForPage: data => {
    return request({
      url: `/route/marketing/web/mall/sku/queryPriceForPage`,
      data,
    });
  },
  /** 商品管理-商品名称唯一校验 */
  checkUniqueSkuName: data => {
    return request({
      url: `/route/marketing/web/mall/sku/checkUniqueSkuName`,
      data,
    });
  },
  /** 商品管理-商品编号唯一校验 */
  checkUniqueSkuNo: data => {
    return request({
      url: `/route/marketing/web/mall/sku/checkUniqueSkuNo`,
      data,
    });
  },
};

/** 营销卡券管理 */
export const marketManage = {
  /** 营销（平台/商户）卡券管理-新增 */
  add: data => {
    return request({
      url: `/route/marketing/web/cardCoupon/add`,
      data,
    });
  },
  /** 营销（平台/商户）卡券管理-编辑 */
  edit: data => {
    return request({
      url: `/route/marketing/web/cardCoupon/updateMarketingCardCouponStation`,
      data,
    });
  },
  /** 营销（平台/商户）卡券管理-卡券名称唯一性校验 */
  unique: data => {
    return request({
      url: `/route/marketing/web/cardCoupon/cardCouponName/unique`,
      data,
      notShowError: true,
    });
  },
  /** 营销（平台/商户）卡券管理-详情 */
  detail: data => {
    return request({
      url: `/route/marketing/web/cardCoupon/detail`,
      data,
    });
  },
  /** 营销（平台/商户）卡券管理-作废 */
  cancel: data => {
    return request({
      url: `/route/marketing/web/cardCoupon/cancel`,
      data,
    });
  },
  /** 营销（平台/商户）卡券管理-修改库存 */
  stock: data => {
    return request({
      url: `/route/marketing/web/cardCoupon/update/stock`,
      data,
    });
  },
  /** 营销（平台/商户）卡券管理-分页列表 */
  page: data => {
    return request({
      url: `/route/marketing/web/cardCoupon/page`,
      data,
    });
  },
  /** 营销（平台/商户）卡券领取明细-分页列表 */
  detailPage: data => {
    return request({
      url: `/route/marketing/web/userCardCoupon/receive/detail/page`,
      data,
    });
  },
  /** 营销（平台/商户）卡券领取明细-作废 */
  detailCancel: data => {
    return request({
      url: `/route/marketing/web/userCardCoupon/receive/detail/cancel`,
      data,
      notShowError: true,
    });
  },
  /** 营销（平台/商户）卡券-下拉列表-for活动 */
  activityList: data => {
    return request({
      url: `/route/marketing/web/cardCoupon/forActivity/list`,
      data,
    }).then(res => {
      if (!res || !res.length) {
        return res;
      }
      return res.map(item => ({
        ...item,
        cardCouponName: `${item.cardCouponName}(${item.cardCouponId})`,
      }));
    });
  },
  /** 营销（平台/商户）卡券管理-详情电站分页列表   */
  stationPage: data => {
    return request({
      url: `/route/marketing/web/cardCoupon/station/page`,
      data,
    });
  },
  /** 营销（平台/商户）卡券管理-校验库存 */
  checkStock: data => {
    return request({
      url: `/route/marketing/web/cardCoupon/check/stock`,
      data,
    });
  },
  /** 会员积分规则配置 */
  savePointsRuleConfig: data => {
    return request({
      url: `/route/marketing/web/member/rule/savePointsRuleConfig`,
      data,
    });
  },
  /** 会员成长值手动调整 */
  growthManualAdjust: data => {
    return request({
      url: `/route/marketing/web/member/growthManualAdjust`,
      data,
    });
  },
  /** 会员积分手动调整 */
  pointsManualAdjust: data => {
    return request({
      url: `/route/marketing/web/member/pointsManualAdjust`,
      data,
    });
  },
  /** 会员成长值规则配置 */
  saveGrowthRuleConfig: data => {
    return request({
      url: `/route/marketing/web/member/rule/saveGrowthRuleConfig`,
      data,
    });
  },
  /** 积分规则详情 */
  showPointsRuleConfig: data => {
    return request({
      url: `/route/marketing/web/member/rule/showPointsRuleConfig`,
      data,
    });
  },
  /** 成长值规则详情 */
  showGrowthRuleConfig: data => {
    return request({
      url: `/route/marketing/web/member/rule/showGrowthRuleConfig`,
      data,
    });
  },
  /** 会员列表查询 */
  memberQueryForPage: data => {
    return request({
      url: `/route/marketing/web/member/queryForPage`,
      data,
    });
  },
  /** 会员详情查询 */
  memberDetails: data => {
    return request({
      url: `/poly/web/member/detail`,
      data,
    });
  },
  /** 会员成长值流水列表 */
  queryGrowthFlowForPage: data => {
    return request({
      url: `/route/marketing/web/member/queryGrowthFlowForPage`,
      data,
    });
  },
  /** 会员积分流水列表 */
  queryPointsFlowForPage: data => {
    return request({
      url: `/route/marketing/web/member/queryPointsFlowForPage`,
      data,
    });
  },
  /** 会员详情查询 */
  queryCardCouponByUserId: data => {
    return request({
      url: `/route/marketing/web/userCardCoupon/receive/detail/queryCardCouponByUserId`,
      data,
    });
  },
  /** 会员详情查询 */
  findLevelInfoByUserId: data => {
    return request({
      url: `/route/marketing/web/member/findLevelInfoByUserId`,
      data,
    });
  },
  /** 会员等级列表 */
  memberLevelList: data => {
    return request({
      url: `/route/marketing/web/member/rule/list`,
      data,
    });
  },
  /** 获取会员等级规则 */
  findLevelInfoByLevel: data => {
    return request({
      url: `/route/marketing/web/member/rule/findLevelInfoByLevel`,
      data,
    });
  },
  /** 会员等级规则配置 */
  memberLevelRuleConfig: data => {
    return request({
      url: `/route/marketing/web/member/rule/levelRuleConfig`,
      data,
    });
  },
  /** 检验卡的有效性 */
  cardCheck: data => {
    return request({
      url: `/route/marketing/web/charging/card/checkBind`,
      data,
    });
  },

  /** 站点运营配置 */
  stationFindByPage: data => {
    return request({
      url: `/route/station/web/findByPage`,
      data,
    });
  },
  /** soc设置 */
  updateStationSoc: data => {
    return request({
      url: `/route/station/web/updateStationSoc`,
      data,
    });
  },
  /** 根据电站id查询电桩信息 */
  findStationAndPile: data => {
    return request({
      url: `/route/station/web/pile/findPileByStationId`,
      data,
    });
  },
  /** 根据电站id查询电桩信息 */
  findStationPile: params => {
    return request({
      method: 'GET',
      url: `/route/station/web/pile/getStationPileDown`,
      params,
    });
  },
  /** 站点运营配置双枪并充配置 */
  updateStationIsMoreCharge: data => {
    return request({
      url: `/route/station/web/updateStationIsMoreCharge`,
      data,
    });
  },
  /** 更新预约 */
  updateStationIsAppoint: data => {
    return request({
      url: `/route/station/web/updateStationIsAppoint`,
      data,
    });
  },
  /** 更新即插即充 */
  updatePlugCharge: data => {
    return request({
      url: `/route/station/web/updatePlugCharge`,
      data,
    });
  },
  /* 可用站点配置列表 */
  availableStationIsConfig: data => {
    return request({
      url: `/route/user/web/available/station/config/page`,
      data,
    });
  },
  /* 可用站点列表编辑 */
  updateStationIsConfig: data => {
    return request({
      url: `/route/user/web/available/station/config/edit`,
      data,
    });
  },
  /* 可用电站详情--电站分页 */
  StationIsConfigDetailPage: data => {
    return request({
      url: `/poly/web/available/station/config/station/page`,
      data,
    });
  },
  /* 可用电站详情 */
  StationIsConfigDetail: data => {
    return request({
      url: `/route/user/web/available/station/config/detail`,
      data,
    });
  },
};

/** 角色管理 */
export const roleManage = {
  /** 查询角色管理列表 */
  queryUserRoleList: data => {
    return request({
      url: `/route/user/web/user/role/queryUserRoleList`,
      data,
    });
  },
  /** 角色管理保存 */
  save: data => {
    return request({
      url: `/route/user/web/user/role/save`,
      data,
    });
  },
  /** 角色管理详情 */
  detail: data => {
    return request({
      url: `/route/user/web/user/role/detail`,
      data,
    });
  },
  /** 角色管理删除(批量) */
  batchDelete: data => {
    return request({
      url: `/route/user/web/user/role/batchDelete`,
      data,
    });
  },
  /** 角色管理关联展示 */
  relation: data => {
    return request({
      url: `/route/user/web/user/role/relation`,
      data,
    });
  },
  /** 角色管理关联保存 */
  relationSave: data => {
    return request({
      url: `/route/user/web/user/role/relationSave`,
      data,
    });
  },
  /** 账号新增-角色列表 */
  queryRoleListByRoleType: data => {
    return request({
      url: `/route/user/web/user/role/queryRoleListByRoleType`,
      data,
    });
  },
  /** 角色管理详情-账号管理列表 */
  detailAccountList: data => {
    return request({
      url: `/route/user/web/user/role/detailAccountList`,
      data,
    });
  },
};

/** 账号管理 */
export const accountManage = {
  /** 查询账号管理列表 */
  queryUserAccountList: data => {
    return request({
      url: `/route/user/web/user/account/queryUserAccountList`,
      data,
    });
  },
  /** 账号管理保存 */
  save: data => {
    return request({
      url: `/route/user/web/user/account/save`,
      data,
    });
  },
  /** 账号管理改密 */
  updatePass: data => {
    return request({
      url: `/route/user/web/user/account/updatePass`,
      data,
    });
  },
  /** 账号管理详情 */
  detail: data => {
    return request({
      url: `/route/user/web/user/account/detail`,
      data,
    });
  },
  /** 账号管理停用 */
  stop: data => {
    return request({
      url: `/route/user/web/user/account/stop`,
      data,
    });
  },
  /** 账号管理删除(批量) */
  batchDelete: data => {
    return request({
      url: `/route/user/web/user/account/batchDelete`,
      data,
    });
  },
  /** 账号管理详情-数据权限列表（聚合） */
  detailDataPermsList: data => {
    return request({
      url: `/poly/web/account/detailDataPermsList`,
      data,
    });
  },
  // 获取部门信息
  getDepartmentList: data => {
    return request({
      url: `/route/support/web/project/getDepartmentList`,
      data,
    });
  },
  // 获取角色Bd用户信息
  getUserAccountByBd: () => {
    return request({
      method: 'GET',
      url: `/route/user/web/user/account/getUserAccountByBd`,
    });
  },
  // 获取所有用户
  getUserAccountAll: () => {
    return request({
      method: 'GET',
      url: `/route/user/web/user/account/getUserAccountAll`,
    });
  },
};

/** 操作日志 */
export const sysLog = {
  /** 系统操作日志分页列表 */
  queryLogList: data => {
    return request({
      url: `/route/support/web/sys/log/queryLogList`,
      data,
    });
  },
  /** 系统操作日志详情 */
  queryLogDetail: data => {
    return request({
      url: `/route/support/web/sys/log/queryLogDetail`,
      data,
    });
  },
};

/** 系统配置 */
export const sysCnf = {
  /** 系统配置保存 */
  save: data => {
    return request({
      url: `/route/support/web/sys/cnf/save`,
      data,
    });
  },
  /** 系统配置详情 */
  detail: data => {
    return request({
      url: `/route/support/web/sys/cnf/detail`,
      data,
    });
  },
  /** 简化版系统配置详情 */
  simpleCnf: data => {
    return request({
      url: `/route/support/web/sys/cnf/simpleCnf`,
      data,
      notShowError: true,
    });
  },
};

/** 下载中心 */
export const sysDownload = {
  /** 下载中心列表 */
  queryDownFileList: data => {
    return request({
      url: `/route/support/web/sys/download/queryDownFileList`,
      data,
    });
  },
  /** 下载中心删除 */
  delete: data => {
    return request({
      url: `/route/support/web/sys/download/delete`,
      data,
    });
  },
  /** 单文件下载 */
  download: data => {
    return request({
      url: `/route/support/web/sys/download/download`,
      data,
    });
  },
  /** 批量下载 */
  batchDownload: data => {
    return request({
      url: `/route/support/web/sys/download/batchDownload`,
      data,
    });
  },
  /** 全局离线下载 */
  offlineExport: data => {
    return request({
      url: `/route/support/web/sys/download/offlineExport`,
      data,
    });
  },
};

/** 消息管理 */
export const sysNotice = {
  /** 查询系统消息列表 */
  queryNoticeList: data => {
    return request({
      url: `/route/support/web/sys/notice/queryNoticeList`,
      data,
    });
  },
  /** 系统消息保存 */
  save: data => {
    return request({
      url: `/route/support/web/sys/notice/save`,
      data,
    });
  },
  /** 系统消息推送商户信息 */
  pushOperatorList: data => {
    return request({
      url: `/route/support/web/sys/notice/pushOperatorList`,
      data,
    });
  },
  /** 系统消息删除 */
  delete: data => {
    return request({
      url: `/route/support/web/sys/notice/delete`,
      data,
    });
  },
  /** 系统消息全部已读 */
  allRead: data => {
    return request({
      url: `/route/support/web/sys/notice/allRead`,
      data,
    });
  },
  /** 根据商户查询系统消息列表 */
  queryNoticeListByOpera: data => {
    return request({
      url: `/poly/web/account/queryNoticeListByAccNo`,
      data,
    });
  },
  /** 系统消息批量下载 */
  onlineExport: data => {
    return request({
      url: `/route/support/web/sys/notice/onlineExport`,
      data,
    });
  },
  /** 推送商户下载 */
  onlineExportOpera: data => {
    return request({
      url: `/route/support/web/sys/notice/onlineExportOpera`,
      data,
    });
  },
};

/**
 * 平台价格活动:
 * @type {{}}
 */
export const platformPriceActivity = {
  // 平台价格活动分页查询
  list: data => {
    return request({
      url: '/route/marketing/web/activity/platformPrice/queryForPage',
      data,
    });
  },
  // 平台价格活动新增
  add: data => {
    return request({
      url: '/route/marketing/web/activity/platformPrice/add',
      data,
    });
  },
  // 平台价格活动编辑
  edit: data => {
    return request({
      url: '/route/marketing/web/activity/platformPrice/edit',
      data,
    });
  },
  // 平台价格活动详情
  detail: data => {
    return request({
      url: '/route/marketing/web/activity/platformPrice/detail',
      data,
    });
  },
  // 平台价格活动分页查询导出
  export: (fileName, body) => {
    return request({
      url: '/activity/platformPrice/export',
      data: { body },
    });
  },
  // 平台价格活动校验活动名称唯一
  checkOnlyActivityName: data => {
    return request({
      url: '/route/marketing/web/activity/platformPrice/checkActivityName',
      data,
    });
  },
  // 平台价格活动停止
  stop: data => {
    return request({
      url: '/route/marketing/web/activity/platformPrice/stop',
      data,
    });
  },
  // 根据电站ID查询进行中的活动列表
  ongoingList: data => {
    return request({
      url: '/route/marketing/web/activity/price/queryIngForPage',
      data,
    });
  },
  // 平台价格活动详情电站分页查询
  detailStations: data => {
    return request({
      url: '/route/marketing/web/activity/platformPrice/queryStationForPage',
      data,
    });
  },
  // 平台价格活动详情电站分页查询导出
  exportDetailStations: (fileName, body) => {
    return request({
      url: '/route/marketing/activity/web/platformPrice/exportStationForPage',
      data: { body },
    });
  },
  // 平台价格活动详情用户分页查询
  detailUsers: data => {
    return request({
      url: '/route/marketing/web/activity/platformPrice/queryUserForPage',
      data,
    });
  },
  // 平台价格活动详情用户分页查询导出
  exportDetailUsers: (fileName, body) => {
    return request({
      url: '/route/marketing/activity/web/platformPrice/exportOrgForPage',
      data: { body },
    });
  },
  // 平台价格活动详情电站、用户分组分页查询
  detailUserOrStationGroups: data => {
    return request({
      url: '/route/marketing/web/activity/platformPrice/queryGroupForPage',
      data,
    });
  },
  // 平台价格活动详情用户分组分页查询导出
  exportDetailUserGroups: data => {
    return request({
      url: '/route/marketing/activity/web/platformPrice/exportGroupForPage',
      data,
    });
  },
};

/**
 * 商户价格活动:
 * @type {{}}
 */
export const merchantPriceActivity = {
  // 商户价格活动分页查询
  list: data => {
    return request({
      url: '/route/marketing/web/activity/operatorPrice/queryForPage',
      data,
    });
  },
  // 商户价格活动新增
  add: data => {
    return request({
      url: '/route/marketing/web/activity/operatorPrice/add',
      data,
    });
  },
  // 商户价格活动编辑
  edit: data => {
    return request({
      url: '/route/marketing/web/activity/operatorPrice/edit',
      data,
    });
  },
  // 商户价格活动详情
  detail: data => {
    return request({
      url: '/route/marketing/web/activity/operatorPrice/detail',
      data,
    });
  },
  //  商户价格活动分页查询导出
  export: (fileName, body) => {
    return request({
      url: '/route/activity/platformPrice/export',
      data: { body },
    });
  },
  // 商户价格活动校验活动名称唯一
  checkOnlyActivityName: data => {
    return request({
      url: '/route/marketing/web/activity/operatorPrice/checkActivityName',
      data,
    });
  },
  // 商户价格活动停止
  stop: data => {
    return request({
      url: '/route/marketing/web/activity/operatorPrice/stop',
      data,
    });
  },
  // 商户价格活动详情电站分页查询
  detailStations: data => {
    return request({
      url: '/route/marketing/web/activity/operatorPrice/queryStationForPage',
      data,
    });
  },
  // 商户价格活动详情电站分页查询导出
  exportDetailStations: (fileName, body) => {
    return request({
      url: '/route/activity/operatorPrice/detail/exportStationForPage',
      data: { body },
    });
  },
  // 商户价格活动详情用户分页查询
  detailUsers: data => {
    return request({
      url: '/route/marketing/web/activity/operatorPrice/queryUserForPage',
      data,
    });
  },
  // 商户价格活动详情用户分页查询导出
  exportDetailUsers: (fileName, body) => {
    return request({
      url: '/route/activity/operatorPrice/detail/exportOrgForPage',
      data: { body },
    });
  },
  // 商户价格活动详情电站、用户分组分页查询
  detailUserOrStationGroups: data => {
    return request({
      url: '/route/marketing/web/activity/operatorPrice/queryGroupForPage',
      data,
    });
  },
  // 商户价格活动详情用户分组分页查询导出
  exportDetailUserGroups: data => {
    return request({
      url: '/route/activity/operatorPrice/detail/exportUserGroupForPage',
      data,
    });
  },
};

/**
 * 卡券活动:适用于平台卡券活动和商户卡券活动
 * @type {{}}
 */
export const couponActivity = {
  // 列表
  list: data => {
    return request({
      url: '/route/marketing/web/cardCouponActivity/queryForPage',
      data,
    });
  },
  // 导出
  export: (fileName, body) => {
    return exportFile({
      url: '/route/marketing/web/cardCouponActivity/export',
      data: { body },
      fileName,
    });
  },
  // 停止
  stop: data => {
    return request({
      url: '/route/marketing/web/cardCouponActivity/stop',
      data,
    });
  },
  // 活动名称判重
  checkNameOnly: data => {
    return request({
      url: '/route/marketing/web/cardCouponActivity/nameRepeat',
      data,
    });
  },
  // 新增/编辑(注册活动)
  registerActivitySave: data => {
    return request({
      url: '/route/marketing/web/cardCouponActivity/registerActivity/submit',
      data,
    });
  },
  // 详情(注册活动)
  registerActivityDetail: data => {
    return request({
      url: '/route/marketing/web/cardCouponActivity/registerActivity/detail',
      data,
    });
  },
  // 新增/编辑(充值活动)
  rechargeActivitySave: data => {
    return request({
      url: '/route/marketing/web/cardCouponActivity/rechargeActivity/submit',
      data,
    });
  },
  // 详情(充值活动)
  rechargeActivityDetail: data => {
    return request({
      url: '/route/marketing/web/cardCouponActivity/rechargeActivity/detail',
      data,
    });
  },
  // 详情(充值活动电站)
  rechargeActivityStation: data => {
    return request({
      url: '/route/marketing/web/cardCouponActivity/rechargeActivity/queryRechargeActivityStation',
      data,
    });
  },
  // 新增/编辑(定向发放活动)
  orientationActivitySave: data => {
    return request({
      url: '/route/marketing/web/cardCouponActivity/directionalActivity/submit',
      data,
    });
  },
  // 详情(定向发放活动)
  orientationActivityDetail: data => {
    return request({
      url: '/route/marketing/web/cardCouponActivity/directionalActivity/detail',
      data,
    });
  },
  // 定向发放用户列表
  orientationActivityUsers: data => {
    return request({
      url: '/route/marketing/web/cardCouponActivity/directionalActivity/user/queryForPage',
      data,
    });
  },
  // 导出定向发放用户列表
  exportOrientationActivityUsers: (fileName, body) => {
    return request({
      url: '/route/marketing/web/cardCouponActivity/directionalActivity/user/export',
      data: { body },
    });
  },

  // 新增/编辑(充电活动)
  chargeActivitySave: data => {
    return request({
      url: '/route/marketing/web/cardCouponActivity/chargeActivity/submit',
      data,
    });
  },
  // 详情(充电活动)
  chargeActivityDetail: data => {
    return request({
      url: '/route/marketing/web/cardCouponActivity/chargeActivity/detail',
      data,
    });
  },
  // 充电领取活动用户列表
  chargeActivityUsers: data => {
    return request({
      url: '/route/marketing/web/cardCouponActivity/chargeActivity/user/queryForPage',
      data,
    });
  },
  // 充电领取活动用户列表导出
  exportChargeActivityUsers: (fileName, body) => {
    return request({
      url: '/route/marketing/web/cardCouponActivity/chargeActivity/user/export',
      data: { body },
    });
  },
  // 充电领取活动电站列表
  chargeActivityStations: data => {
    return request({
      url: '/route/marketing/web/cardCouponActivity/chargeActivity/station/queryForPage',
      data,
    });
  },
  // 充电领取活动电站列表导出
  exportChargeActivityStations: (fileName, body) => {
    return request({
      url: '/route/marketing/web/cardCouponActivity/chargeActivity/station/export',
      data: { body },
    });
  },
  // 扫码领取活动新增/编辑
  scanActivitySave: data => {
    return request({
      url: '/route/marketing/web/cardCouponActivity/scanActivity/submit',
      data,
    });
  },
  // 扫码领取活动新增/编辑
  scanActivityDetail: data => {
    return request({
      url: '/route/marketing/web/cardCouponActivity/scanActivity/detail',
      data,
    });
  },
  // 扫码领取活动用户参与（C端)
  scanActivityJoin: data => {
    return request({
      url: '/route/marketing/web/cardCouponActivity/scanActivity/jion',
      data,
    });
  },
  // 扫码领取活动用户列表
  scanActivityUsers: data => {
    return request({
      url: '/route/marketing/web/cardCouponActivity/scanActivity/user/queryForPage',
      data,
    });
  },
  // 扫码领取活动用户列表导出
  exportScanActivityUsers: (fileName, body) => {
    return request({
      url: '/route/marketing/web/cardCouponActivity/scanActivity/user/export',
      data: { body },
    });
  },

  // 券码兑换活动新增/编辑
  exchangeActivitySave: data => {
    return request({
      url: '/route/marketing/web/cardCouponActivity/exchangeActivity/submit',
      data,
    });
  },
  // 券码兑换活动详情
  exchangeActivityDetail: data => {
    return request({
      url: '/route/marketing/web/cardCouponActivity/exchangeActivity/detail',
      data,
    });
  },
  // 券码兑换活动用户参与（C端)
  exchangeActivityJoin: data => {
    return request({
      url: '/route/marketing/web/cardCouponActivity/exchangeActivity/join',
      data,
    });
  },
  // 券码兑换活动用户列表
  exchangeActivityUsers: data => {
    return request({
      url: '/route/marketing/web/cardCouponActivity/exchangeActivity/user/queryForPage',
      data,
    });
  },
  // 券码兑换活动用户列表导出
  exportExchangeActivityUsers: (fileName, body) => {
    return request({
      url: '/route/marketing/web/cardCouponActivity/exchangeActivity/user/export',
      data: { body },
    });
  },
  // 券码兑换活动兑换码导出
  exportConversionCode: (fileName, body) => {
    return request({
      url: '/route/marketing/web/cardCouponActivity/exchangeActivity/conversionCode/export',
      data: { body },
    });
  },
  // 卡券活动领取记录列表分页查询
  couponReceiveRecord: data => {
    return request({
      url: '/route/marketing/web/cardCouponActivity/receiveRecord/queryForPage',
      data,
    });
  },
  // 卡券活动领取记录列表导出
  exportReceiveRecord: (fileName, body) => {
    return request({
      url: '/route/marketing/web/cardCouponActivity/receiveRecord/export',
      data: { body },
    });
  },
  // 批量导入用户的接口
  importUserList: data => {
    return request({
      url: `${USER_MODULE_UPLOAD_PREFIX}chargeUser/file/upload/importUserWithOutOrg`,
      data,
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    });
  },
  // 拉新活动新增或者修改
  InvitingActivitySubmit: data => {
    return request({
      url: '/route/marketing/web/cardCouponActivity/InvitingActivity/submit',
      data,
    });
  },
  // 拉新活动详情
  InvitingActivityDetail: data => {
    return request({
      url: '/route/marketing/web/cardCouponActivity/InvitingActivity/detail',
      data,
    });
  },
};

// 商户 operatorType 1是平台商户  2是互联的
export const operatorData = {
  // 商户分页
  userOperatorPageList: data => {
    return request({
      url: '/poly/web/operator/operatorPageList',
      data,
    });
  },
  // 新增 修改平台商户
  saveOrUpdate: data => {
    return request({
      url: '/route/user/web/operator/saveOrUpdate',
      data,
    });
  },
  // 平台商户详情
  operatorDetail: (params = {}) => {
    return request({
      method: 'GET',
      url: '/route/user/web/operator/operatorDetail',
      params,
    });
  },
  // 导入平台商户
  importOperator: data => {
    return request({
      url: `/upRoute/user/web/operator/importOperator`,
      headers: {
        'Content-Type': 'multipart/form-data',
      },
      data,
    });
  },
  // 导入互联商户
  importLinkOperator: data => {
    return request({
      url: `/upRoute/user/web/operator/importLinkOperator`,
      headers: {
        'Content-Type': 'multipart/form-data',
      },
      data,
    });
  },
  // 平台商户列表
  userOperatorList: data => {
    return request({
      url: '/route/user/web/operator/userOperatorList',
      data,
    });
  },
  /** 归属商户下拉列表查询 带权限 */
  operatorDownList: params => {
    return request({
      url: `/route/user/web/operator/getOperatorDownList`,
      params,
      method: 'GET',
    });
  },
};
// 投资方
export const investmentData = {
  // 投资方分页
  investmentList: data => {
    return request({
      url: '/route/station/web/investment/getInvestmentPage',
      data,
    });
  },
  // 新增 修改投资方
  saveOrUpdate: data => {
    return request({
      url: '/route/station/web/investment/addOrUpdate',
      data,
    });
  },
  // 投资方详情
  investmentDetail: (params = {}) => {
    return request({
      method: 'GET',
      url: '/route/station/web/investment/getInvestmentById',
      params,
    });
  },
  // 投资方删除
  investDelete: data => {
    return request({
      url: '/route/station/web/investment/delete',
      data,
    });
  },
  // 微信商户
  wxMch: data => {
    return request({
      url: '/route/station/web/mch/queryAllStationMch',
      data,
    });
  },
};
// 物业
export const propertymentData = {
  // 物业分页
  propertymentList: data => {
    return request({
      url: '/route/station/web/property/getPropertyPage',
      data,
    });
  },
  // 新增 修改物业
  saveOrUpdate: data => {
    return request({
      url: '/route/station/web/property/addOrUpdate',
      data,
    });
  },
  // 物业详情
  propertymentDetail: (params = {}) => {
    return request({
      method: 'GET',
      url: '/route/station/web/property/getPropertyInfoById',
      params,
    });
  },
  // 物业删除
  // chargeUserDelete: data => {
  //   return request({
  //     url: '/route/station/web/propertyment/delete',
  //     data,
  //   });
  // },
};

// 字典和区域
export const sysDict = {
  // 字典全量数据
  queryDictList: data => {
    return request({
      url: '/route/support/web/sys/dict/queryDictList',
      data,
    });
  },
  // 菜单数据
  querySupportMenuList: data => {
    return request({
      url: '/route/user/web/user/menu/querySupportMenuList',
      data,
    });
  },
  // 功能地图菜单
  queryMapMenuList: data => {
    return request({
      url: '/route/user/web/user/menu/queryMapMenuList',
      data,
      notShowError: true,
    });
  },
  // 按钮权限集合
  queryCodePermission: data => {
    return request({
      url: '/route/user/web/user/menu/queryButtonList',
      data,
      notShowError: true,
    });
  },
  // 字典列表
  dictList: data => {
    return request({
      url: '/route/support/web/sys/dict/findByPage',
      data,
    });
  },
  // 新增字典
  dictAdd: data => {
    return request({
      url: '/route/support/web/sys/dict/add',
      data,
    });
  },
  // 修改字典
  dictUpdate: data => {
    return request({
      url: '/route/support/web/sys/dict/update',
      data,
    });
  },
  // 所有字典类型
  dictAllType: data => {
    return request({
      url: '/route/support/web/sys/dict/findAllType',
      data,
    });
  },
};

// 标签分组接口
export const userLabelApi = {
  // 分组查询人员
  queryForPage: data => {
    return request({
      url: '/route/marketing/web/user/group/queryForPage',
      data,
    });
  },
  // 新增人员分组
  add: data => {
    return request({
      url: '/route/marketing/web/user/group/add',
      data,
    });
  },
  // 新增人员分组
  addByCharge: data => {
    return request({
      url: '/poly/web/user/group/add',
      data,
    });
  },
  // 人员分组停用
  cancel: data => {
    return request({
      url: '/route/marketing/web/user/group/cancel',
      data,
    });
  },
  // 分组查询详情
  detail: data => {
    return request({
      url: '/route/marketing/web/user/group/detail',
      data,
    });
  },
  // 人员分组迁出 迁入
  edit: data => {
    return request({
      url: '/route/marketing/web/user/group/edit',
      data,
    });
  },
  // 人员分组批量迁入迁出
  batchMove: data => {
    return request({
      url: '/upRoute/marketing/web/user/group/batch',
      data,
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    });
  },
  // 分组查询人员
  userGroupList: data => {
    return request({
      url: '/route/marketing/web/user/group/list',
      data,
    });
  },
  //
  uploadFile: data => {
    return request({
      url: '/upRoute/marketing/web/user/group/uploadFile',
      headers: {
        'Content-Type': 'multipart/form-data',
      },
      data,
    });
  },
  // 用户列表分页
  queryUserInfoForPage: data => {
    return request({
      url: '/route/marketing/web/user/group/queryUserInfoForPage',
      data,
    });
  },
};

// 标签分组接口
export const stationLabelApi = {
  // 分组查询电站
  queryForPage: data => {
    return request({
      url: '/route/marketing/web/station/group/queryForPage',
      data,
    });
  },
  // 新增电站分组
  add: data => {
    return request({
      url: '/route/marketing/web/station/group/add',
      data,
    });
  },
  // 电站分组停用
  cancel: data => {
    return request({
      url: '/route/marketing/web/station/group/cancel',
      data,
    });
  },
  // 分组查询详情
  detail: data => {
    return request({
      url: '/route/marketing/web/station/group/detail',
      data,
    });
  },
  // 电站分组迁入
  edit: data => {
    return request({
      url: '/route/marketing/web/station/group/edit',
      data,
    });
  },

  // 电站分组批量迁入迁出
  batch: data => {
    return request({
      url: '/upRoute/marketing/web/station/group/batch',
      data,
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    });
  },
  // 分组查询电站
  stationGroupList: data => {
    return request({
      url: '/route/marketing/web/station/group/list',
      data,
    });
  },
  uploadFile: data => {
    return request({
      url: '/upRoute/marketing/web/station/group/uploadFile',
      headers: {
        'Content-Type': 'multipart/form-data',
      },
      data,
    });
  },
  // 电站lable 电站分页查询
  queryStationForPage: data => {
    return request({
      url: '/route/marketing/web/station/group/queryStationForPage',
      data,
    });
  },
  // 查询电站分组树
  queryStationTree: data => {
    return request({
      url: '/route/marketing/web/station/group/queryStationTree',
      data,
    });
  },
};

// 广告部分接口
export const adsApi = {
  // 分页查询电站
  queryForPage: data => {
    return request({
      url: '/route/marketing/web/app/advertisement/queryForPage',
      data,
    });
  },
  // 查询可用广告
  queryForPage2: data => {
    return request({
      url: '/route/marketing/web/app/advertisement/queryForPage',
      data,
    });
  },
  // 查询广告详情
  detail: data => {
    return request({
      url: '/route/marketing/web/app/advertisement/detail',
      data,
    });
  },
  // 广告详情-活动用户列表
  queryCustomerForPage: data => {
    return request({
      url: '/route/marketing/web/app/advertisement/queryCustomerForPage',
      data,
    });
  },
  // 广告详情-用户分组列表
  queryUserGroupForPage: data => {
    return request({
      url: '/route/marketing/web/app/advertisement/queryUserGroupForPage',
      data,
    });
  },
  // 新增广告
  add: data => {
    return request({
      url: '/route/marketing/web/app/advertisement/add',
      data,
    });
  },
  // 编辑广告
  edit: data => {
    return request({
      url: '/route/marketing/web/app/advertisement/edit',
      data,
    });
  },
  // 停用广告
  stop: data => {
    return request({
      url: '/route/marketing/web/app/advertisement/stop',
      data,
    });
  },
};

// 充电卡管理
export const chargingCardManage = {
  // 充电卡分页查询
  chargeQueryForPage: data => {
    return request({
      url: '/route/marketing/web/charging/card/queryForPage',
      data,
    });
  },
  // 充电卡编辑
  chargeEdit: data => {
    return request({
      url: '/route/marketing/web/charging/card/edit',
      data,
    });
  },
  // 充电卡新增
  chargeAdd: data => {
    return request({
      url: '/route/marketing/web/charging/card/add',
      data,
    });
  },
  // 充电卡作废
  chargeCancel: data => {
    return request({
      url: '/route/marketing/web/charging/card/cancel',
      data,
    });
  },
  // 充电卡批量导入
  chargeBatchImport: data => {
    return request({
      url: '/upRoute/marketing/web/charging/card/batchImport',
      data,
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    });
  },
  // 离线卡分页查询
  offQueryForPage: data => {
    return request({
      url: '/route/marketing/web/offline/card/queryForPage',
      data,
    });
  },
  // 离线卡重新下发
  offReissue: data => {
    return request({
      url: '/poly/web/offline/card/reissue',
      data,
    });
  },
  // 离线卡清除
  offClear: data => {
    return request({
      url: '/poly/web/offline/card/clear',
      data,
    });
  },
  // 离线卡详情
  offDetail: data => {
    return request({
      url: '/route/marketing/web/offline/card/detail',
      data,
    });
  },
  // 绑定离线卡
  offBind: data => {
    return request({
      url: '/poly/web/offline/card/bindCard',
      data,
    });
  },
  // 根据电站名精确查找电桩信息
  offQueryPileInfo: data => {
    return request({
      url: '/route/marketing/web/offline/card/queryPileInfo',
      data,
    });
  },
  // 校验卡号是否存在/是否绑定用户
  offCheck: data => {
    return request({
      url: '/route/marketing/web/charging/card/check',
      data,
      notShowError: true,
    });
  },
};

/** 树接口 */
export const treeUtils = {
  // 电站树
  operatorStations: data => {
    return request({
      url: '/route/station/web/findOperatorStationTree',
      data,
    }).then(res => {
      if (!res || !res.length) {
        return res;
      }
      return res.map(item => ({
        ...item,
        operatorName: item.operatorName || '未知',
        operatorId: item.operatorId * 10000000,
      }));
    });
  },
  // 电站分组
  operatorStationGroup: data => {
    return request({
      url: '/route/marketing/web/station/group/list',
      data,
    });
  },
  // 用户树
  operatorUsers: data => {
    return request({
      url: '/route/user/web/org/getAllOrgInfoGroupByType',
      data,
    });
  },
  // 查询主机构分组(不包含客户机构)
  operatorUsersNocustomrerOrg: data => {
    return request({
      url: '/route/user/web/org/getOrgInfoGroupByType_noCustomerOrg',
      data,
    });
  },
  // 新的用户树 史超 广告新增
  getAllOrgInfoGroupByTypeWithOutZdl: data => {
    return request({
      url: '/route/user/web/org/getAllOrgInfoGroupByTypeWithOutZdl',
      data,
    });
  },
  // 用户分组
  operatorUserGroup: data => {
    return request({
      url: '/route/marketing/web/user/group/list',
      data,
    });
  },
  // 区域数据
  getDistrict: data => {
    return request({
      url: '/route/support/web/sys/district/getDistrict',
      data,
    });
  },
  // 按城市筛选电站
  findCityStationTree: data => {
    return request({
      url: '/route/station/web/findCityStationTree',
      data,
    });
  },
  // 车辆库下拉框
  queryCarListByOrgId: data => {
    return request({
      url: '/route/user/web/car/queryCarListByOrgId',
      data,
    });
  },
};

/** 通用接口 */
export const commonUtils = {
  // 电站树
  login: data => {
    return request({
      url: '/security/login',
      data,
    });
  },
  /** 获取用户登录的验证码方式 */
  getLoginVerifyTypeOrImgVerifyCode: (data = {}) => {
    return request({
      url: `${USER_MODULE_PREFIX}user/account/getLoginVerifyTypeOrImgVerifyCode`,
      data,
    });
  },
  /** 获取登录短信验证码 */
  sendVerifyCodeByAccount: data => {
    return request({
      url: `/security/sendVerifyCodeByAccount`,
      data,
    });
  },
};

// 上传文件
export const uploadUtils = {
  // 文件上传
  uploadFile: data => {
    return request({
      url: '/upRoute/support/web/sys/file/uploadFile',
      data,
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    });
  },
  // 图片上传
  uploadImg: data => {
    return request({
      url: '/upRoute/support/web/sys/file/uploadImg',
      data,
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    });
  },
  // 文件上传并同步飞书
  uploadFileSyncFeishu: data => {
    return request({
      url: '/upRoute/support/web/sys/file/uploadFileOssAndFs',
      data,
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    });
  },
  // 图片上传并同步飞书
  uploadImgSyncFeishu: data => {
    return request({
      url: '/upRoute/support/web/sys/file/uploadImgOssAndFs',
      data,
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    });
  },
};

/**
 * @description 运营 用户黑名单
 * */
export const operationsUserDenyList = {
  // 列表接口
  list: data => {
    return request({
      url: `${OPERATION_USER_DENY_PREFIX}blackList/findByPage`,
      data,
    });
  },
  // 启用或停用
  startOrStop: data => {
    return request({
      url: `${USER_MODULE_PREFIX}black/setting/useSwitch`,
      data,
    });
  },
  // 批量导入用户的接口
  importUser4BlackList: data => {
    return request({
      url: `${USER_MODULE_UPLOAD_PREFIX}chargeUser/file/upload/importUser4BlackList`,
      data,
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    });
  },
  // 远程用户
  remoteUser: data => {
    return request({
      url: `${USER_MODULE_PREFIX}chargeUser/queryUserInfoLikeAccount`,
      data,
    });
  },
  // 新建黑名单
  createNewDenyItem: data => {
    return request({
      url: `${USER_MODULE_PREFIX}/black/setting/add`,
      data,
    });
  },
  // 更新黑名单
  updateDenyItem: data => {
    return request({
      url: `${USER_MODULE_PREFIX}black/setting/update`,
      data,
    });
  },
  // 详情列表
  stationLIst: data => {
    return request({
      url: `${STATION_MODULE_PREFIX}findByPage`,
      data,
    });
  },
};

// 运维管理 API
export const omManagementApi = {
  // 文件上传 --远程升级使用
  uploadFile: data => {
    return request({
      url: `${UP_ROUTE_SUPPORT_PREFIX}sys/file/uploadFtp`,
      data,
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    });
  },
  // 远程升级
  remoteUpdate: data => {
    return request({
      url: `${OM_MANAGEMENT_PREFIX}remoteUpdate`,
      data,
    });
  },
  // 远程升级记录
  remoteUpdateRecords: data => {
    return request({
      url: `${OM_MANAGEMENT_PREFIX}remoteUpdateRecords`,
      data,
    });
  },
  // 查询新直联电桩信息
  omNewZdlInfo: data => {
    return request({
      url: `${STATION_WEB_PREFIX}pile/direct/findPileByPage`,
      data,
    });
  },
  // 告警信息 告警列表
  omAlarmInfoList: data => {
    return request({
      url: `${OM_MANAGEMENT_PREFIX}alarm/query`,
      data,
    });
  },
  //   查询短信通知规则
  // 短信告警规则列表
  // POST /device/web/alarm/querySmsRule
  // 接口ID：64087256
  // 接口地址：https://www.apifox.cn/web/project/2248392/apis/api-64087256
  querySmsRule: data => {
    return request({
      url: `${OM_MANAGEMENT_PREFIX}alarm/querySmsRule`,
      data,
    });
  },
  // 保存/编辑短信告警规则列表
  // POST /device/web/alarm/saveAlarmSmsRule
  saveAlarmSmsRule: data => {
    return request({
      url: `${OM_MANAGEMENT_PREFIX}alarm/saveAlarmSmsRule`,
      data,
    });
  },
  // 保存/编辑告警规则列表
  // POST /device/web/alarm/saveAlarmRule
  saveAlarmRule: data => {
    return request({
      url: `${OM_MANAGEMENT_PREFIX}alarm/saveAlarmRule`,
      data,
    });
  },
  //   告警规则列表
  // POST /device/web/alarm/queryAlarmRule
  // 接口ID：64084858
  // 接口地址：https://www.apifox.cn/web/project/2248392/apis/api-64084858
  queryAlarmRule: data => {
    return request({
      url: `${OM_MANAGEMENT_PREFIX}alarm/queryAlarmRule`,
      data,
    });
  },
  //   设备报文列表
  // POST /device/web/maintenance/query
  // 接口ID：64098326
  // 接口地址：https://www.apifox.cn/web/project/2248392/apis/api-64098326
  maintenanceQuery: data => {
    return request({
      url: `${RECONCILIATION_MANAGEMENT_PREFIX}protocol/queryPage`,
      data,
    });
  },
  // 告警设置列表
  // POST /statistics/web/alarm/set/queryPage
  // 接口ID：4044899
  // 接口地址：https://app.apifox.com/project/4044899
  alarmSetQueryPage: data => {
    return request({
      url: `${RECONCILIATION_MANAGEMENT_PREFIX}alarm/set/queryPage`,
      data,
    });
  },
  // 告警设置编辑
  // POST /statistics/web/alarm/set/update
  // 接口ID：4044899
  // 接口地址：https://app.apifox.com/project/4044899
  alarmSetUpdate: data => {
    return request({
      url: `${RECONCILIATION_MANAGEMENT_PREFIX}alarm/set/update`,
      data,
    });
  },
  // 告警信息列表
  // POST /statistics/web/alarm/info/queryPage
  // 接口ID：4044899
  // 接口地址：https://app.apifox.com/project/4044899
  alarmInfoQueryPage: data => {
    return request({
      url: `${RECONCILIATION_MANAGEMENT_PREFIX}alarm/info/queryPage`,
      data,
    });
  },
  // 告警统计-设备状态统计信息
  // POST /station/web/queryStatusStatistic
  // 接口ID：4044899
  // 接口地址：https://app.apifox.com/project/4044899
  alarmQueryStatusStatistic: data => {
    return request({
      url: `${STATION_MODULE_PREFIX}queryStatusStatistic`,
      data,
    });
  },
  // 告警统计-告警排行榜
  // POST /statistics/web/alarm/queryRankingList
  // 接口ID：4044899
  // 接口地址：https://app.apifox.com/project/4044899
  alarmQueryRankingList: data => {
    return request({
      url: `${RECONCILIATION_MANAGEMENT_PREFIX}alarm/queryRankingList`,
      data,
    });
  },
  // 告警统计-告警次数统计
  // POST /statistics/web/alarm/queryMonthCount
  // 接口ID：4044899
  // 接口地址：https://app.apifox.com/project/4044899
  alarmQueryMonthCount: data => {
    return request({
      url: `${RECONCILIATION_MANAGEMENT_PREFIX}alarm/queryMonthCount`,
      data,
    });
  },
  // 告警统计-告警类型统计
  // POST /statistics/web/alarm/queryTypeCount
  // 接口ID：4044899
  // 接口地址：https://app.apifox.com/project/4044899
  alarmQueryTypeCount: data => {
    return request({
      url: `${RECONCILIATION_MANAGEMENT_PREFIX}alarm/queryTypeCount`,
      data,
    });
  },
  // 查询用户反馈
  feedbackList: data => {
    return request({
      url: `${STATION_MODULE_PREFIX}feedback/queryForPage`,
      data,
    });
  },
  // 处理用户反馈
  replyFeedback: data => {
    return request({
      url: `${STATION_MODULE_PREFIX}feedback/deal`,
      data,
    });
  },
  // 用户反馈详情
  feedbackDetail: data => {
    return request({
      url: `${STATION_MODULE_PREFIX}feedback/queryDetail`,
      data,
    });
  },
  // 模糊查询充电站
  findStationInfoLikeName: data => {
    return request({
      url: `${STATION_MODULE_PREFIX}findStationInfoLikeName`,
      data,
    });
  },
  // 运维信息列表
  operationInfoList: data => {
    return request({
      url: `/poly/web/statisWork/queryWorkPage`,
      data,
    });
  },
  // 运维信息新增
  operationAdd: data => {
    return request({
      url: `${RECONCILIATION_MANAGEMENT_PREFIX}work/addWork`,
      data,
    });
  },
  // 运维信息详情
  operationDetail: params => {
    return request({
      method: 'GET',
      url: `${RECONCILIATION_MANAGEMENT_PREFIX}work/getWorkDetailById`,
      params,
    });
  },
  // 运维信息处理
  operationHandle: data => {
    return request({
      url: `${RECONCILIATION_MANAGEMENT_PREFIX}work/takeWork`,
      data,
    });
  },
  // 运维信息处理人员列表
  operationsUserList: data => {
    return request({
      url: `${USER_MODULE_PREFIX}user/account/getUserAccountInfoDown`,
      data,
    });
  },
};
// 工作台
export const workbench = {
  // 工作台 充电数据
  chargeData: data => {
    return request({
      url: `route/statistics/web/workbench/queryChargeData`,
      data,
    });
  },
  // 工作台 电桩监控
  monitorCount: data => {
    return request({
      url: `route/station/web/pile/monitorCount`,
      data,
    });
  },
  // 场站经营 充电趋势数据
  operationTrend: data => {
    return request({
      url: `route/statistics/web/workbench/stationOperationTrend`,
      data,
    });
  },
  // 场站经营 时段分布数据
  timeSegmentTrend: data => {
    return request({
      url: `route/statistics/web/workbench/timeSegmentTrend`,
      data,
    });
  },
  // 电桩监控分页
  monitorPage: data => {
    return request({
      url: `route/order/web/pile/monitorPage`,
      data,
    });
  },
};

// 商户对账A API
export const reconciliationApi = {
  // #region 运营大屏
  // 运营大屏全量数据
  queryScreenOverview: data => {
    return request({
      url: `route/statistics/web/overview/queryScreenOverview`,
      data,
    });
  },
  // 运营大屏-终端监控信息
  findStationStatistics: data => {
    return request({
      url: `/route/station/web/findStationStatistics`,
      data,
    });
  },
  // 运营大屏-电站地图
  findStationMap: data => {
    return request({
      url: `/route/station/web/findStationMap`,
      data,
    });
  },
  // 运营大屏-今日订单和今日收入(一分钟）
  queryTodayOrderInfoByOne: data => {
    return request({
      url: `/route/statistics/web/overview/queryTodayOrderInfoByOne`,
      data,
    });
  },
  // #endregion
  // 商户列表
  merchantList: data => {
    return request({
      url: `${RECONCILIATION_MANAGEMENT_PREFIX}operatorBill/operatorBillPageList`,
      data,
    });
  },
  // 商户 结算
  settlement: data => {
    return request({
      url: `${RECONCILIATION_MANAGEMENT_PREFIX}operatorBill/operatorLinkBillSettle`,
      data,
    });
  },

  // /statistics/web/operatorBill/operatorBillDetailPageList
  // 商户详情页的账单明细
  merchantDetailList: data => {
    return request({
      url: `${RECONCILIATION_MANAGEMENT_PREFIX}operatorBill/operatorBillDetailPageList`,
      data,
    });
  },
  // 互联互通列表
  interconnectedList: data => {
    return request({
      url: `${RECONCILIATION_MANAGEMENT_PREFIX}linkBill/linkBillPageList`,
      data,
    });
  },
  // 互联互通 结算
  interconnectedSettle: data => {
    return request({
      url: `${RECONCILIATION_MANAGEMENT_PREFIX}linkBill/linkBillSettle`,
      data,
    });
  },
  // 互联互通详情页的账单明细
  linkBillDetailPageList: data => {
    return request({
      url: `${RECONCILIATION_MANAGEMENT_PREFIX}linkBill/linkBillDetailPageList`,
      data,
    });
  },
  //   平台收支账单列表平台账单列表查询
  // POST /statistics/web/platformOrderBill/queryPlatformBillForPage
  // 接口ID：66707815
  // 接口地址：https://www.apifox.cn/web/project/2248392/apis/api-66707815
  queryPlatformBillForPage: data => {
    return request({
      url: `${RECONCILIATION_MANAGEMENT_PREFIX}platformOrderBill/queryPlatformBillForPage`,
      data,
    });
  },
  // 平台收支账单总计查询
  // GET /statistics/web/platformOrderBill/queryPlatformBillTotalByTime
  // 接口ID：66709876
  // 接口地址：https://www.apifox.cn/web/project/2248392/apis/api-66709876
  queryPlatformBillTotalByTime: data => {
    return request({
      url: `${RECONCILIATION_MANAGEMENT_PREFIX}platformOrderBill/queryPlatformBillTotalByTime`,
      data,
    });
  },
  //   平台账单明细列表查询
  // POST /statistics/web/platformOrderBill/queryPlatformBillDetailForPage
  // 接口ID：66712666
  // 接口地址：https://www.apifox.cn/web/project/2248392/apis/api-66712666
  queryPlatformBillDetailForPage: data => {
    return request({
      url: `${RECONCILIATION_MANAGEMENT_PREFIX}platformOrderBill/queryPlatformBillDetailForPage`,
      data,
    });
  },
  //   占位账单列表查询
  // POST /statistics/web/parking/queryBillForPage
  // 接口ID：66715587
  // 接口地址：https://www.apifox.cn/web/project/2248392/apis/api-66715587
  placeholderBillList: data => {
    return request({
      url: `${RECONCILIATION_MANAGEMENT_PREFIX}parking/queryBillForPage`,
      data,
    });
  },
  //   占位账单明细列表查询
  // POST /statistics/web/parking/queryBillDetailForPage
  // 接口ID：66717198
  // 接口地址：https://www.apifox.cn/web/project/2248392/apis/api-66717198
  placeholderBillingDetailsList: data => {
    return request({
      url: `${RECONCILIATION_MANAGEMENT_PREFIX}parking/queryBillDetailForPage`,
      data,
    });
  },
  //   主机构账单
  // POST finance/web/fund/bill/queryMainFundBillForPage
  // 接口ID：65417794
  // 接口地址：https://www.apifox.cn/web/project/2248392/apis/api-65417794
  queryMainFundBillForPage: data => {
    return request({
      url: `${FINANCIAL_MODULE_PREFIX}fund/bill/queryMainFundBillForPage`,
      data,
    });
  },
  //   子机构账单
  // POST finance/web/fund/bill/queryChildFundBillForPage
  // 接口ID：65467921
  // 接口地址：https://www.apifox.cn/web/project/2248392/apis/api-65467921
  queryChildFundBillForPage: data => {
    return request({
      url: `${FINANCIAL_MODULE_PREFIX}fund/bill/queryChildFundBillForPage`,
      data,
    });
  },
  //   主子机构账单流水
  // POST finance/web/fund/bill/queryOrgBillFlowForPage
  // 接口ID：65462135
  // 接口地址：https://www.apifox.cn/web/project/2248392/apis/api-65462135
  queryOrgBillFlowForPage: data => {
    return request({
      url: `${FINANCIAL_MODULE_PREFIX}fund/bill/queryOrgBillFlowForPage`,
      data,
    });
  },
  //   机构流水头部详情
  // POST finance/web/fund/bill/getOrgHeader
  // 接口ID：65707141
  // 接口地址：https://www.apifox.cn/web/project/2248392/apis/api-65707141
  getOrgHeader: data => {
    return request({
      url: `${FINANCIAL_MODULE_PREFIX}fund/bill/getOrgHeader`,
      data,
    });
  },
  //   获取主机构ID名称与ID映射关系
  // POST /user/web/org/queryAllMainOrgList
  // 接口ID：69489250
  // 接口地址：https://www.apifox.cn/web/project/2248392/apis/api-69489250
  queryAllMainOrgList: data => {
    return request({
      url: `${USER_MODULE_PREFIX}org/queryAllMainOrgList`,
      data,
    });
  },
  // 所有流量方名称
  // POST /user/web/chargeUser/queryAllZdlUser
  // 接口ID：69661680
  // 接口地址：https://www.apifox.cn/web/project/2248392/apis/api-69661680
  queryAllZdlUser: data => {
    return request({
      url: `${USER_MODULE_PREFIX}org/queryFlowOrgList`,
      data,
    });
  },
};

/** APP升级管理 */
export const upgradeManage = {
  /** 查询列表 */
  queryUpgradeList: data => {
    return request({
      url: '/route/support/web/sys/upgrade/queryForPage',
      data,
    });
  },
  addUpgrade: data => {
    return request({
      url: '/route/support/web/sys/upgrade/add',
      data,
    });
  },
  editUpgrade: data => {
    return request({
      url: '/route/support/web/sys/upgrade/edit',
      data,
    });
  },
  uploadFile: config => {
    return request({
      url: '/upRoute/support/web/sys/file/uploadFile',
      headers: {
        'Content-Type': 'multipart/form-data',
      },
      ...config,
    });
  },
  getOssConfig: () => {
    return request({
      method: 'GET',
      url: '/route/support/web/sys/file/getSignature',
    });
  },
};

/** 分成信息 */
export const dividedIntoInformationsApi = {
  list: data => {
    return request({
      url: `/poly/web/divide/queryForPage`,
      data,
    });
  },
};

/** 物业结算管理 */
export const propertySettleManage = {
  /** 查询列表 */
  queryList: data => {
    return request({
      url: `${FINANCIAL_MODULE_PREFIX}property/settle/pagePropertySettlePage`,
      data,
    });
  },
  addOne: data => {
    return request({
      url: `${FINANCIAL_MODULE_PREFIX}property/settle/addPropertySettle`,
      data,
    });
  },
  // 物业下拉列表
  propertyList: params => {
    return request({
      url: `${STATION_WEB_PREFIX}/property/getPropertyListDown`,
      params,
      method: 'GET',
    });
  },
  // 电站下拉列表
  stationList: params => {
    return request({
      url: `${FINANCIAL_MODULE_PREFIX}property/settle/getPropertySettleStationDown`,
      params,
      method: 'GET',
    });
  },
  // 详情
  getOne: params => {
    return request({
      method: 'GET',
      params,
      url: `${FINANCIAL_MODULE_PREFIX}property/settle/getPropertySettleDetailInfo`,
    });
  },
  // 导出对账单
  exportStatement: params => {
    return request({
      url: `${FINANCIAL_MODULE_PREFIX}property/settle/exportStatement`,
      method: 'GET',
      params,
    });
  },
  // 导出明细
  exportDetail: params => {
    return request({
      url: `${FINANCIAL_MODULE_PREFIX}property/settle/exportDetail`,
      method: 'GET',
      params,
    });
  },
  // 导出月份明细
  exportMonthDetail: params => {
    return request({
      url: `${FINANCIAL_MODULE_PREFIX}property/settle/exportMonthDetail`,
      method: 'GET',
      params,
    });
  },
  // 审核结算
  auditSettle: data => {
    return request({
      url: `${FINANCIAL_MODULE_PREFIX}property/settle/auditSettle`,
      data,
    });
  },
  // 确认发票
  confirmInvoice: data => {
    return request({
      url: `${FINANCIAL_MODULE_PREFIX}property/settle/confirmInvoice`,
      data,
    });
  },
  // 确认收款
  confirmPayment: data => {
    return request({
      url: `${FINANCIAL_MODULE_PREFIX}property/settle/confirmPayment`,
      data,
    });
  },
  // 结算详情
  getSettleInfo: data => {
    return request({
      url: `${FINANCIAL_MODULE_PREFIX}property/settle/getPropertySysSettleInfo`,
      data,
    });
  },
};
export const reportforms = {
  // 公桩余额变动列表
  getPublicPileList: data => {
    return request({
      url: `${RECONCILIATION_MANAGEMENT_PREFIX}account/change/queryPage`,
      data,
    });
  },
  // 公桩余额变动总计
  getPublicPileAmount: data => {
    return request({
      url: `${RECONCILIATION_MANAGEMENT_PREFIX}account/change/queryTotalAndAvgAmount`,
      data,
    });
  },
  // 充电站运营报表
  getStationOperationList: data => {
    return request({
      url: `${RECONCILIATION_MANAGEMENT_PREFIX}operate/order/queryPage`,
      data,
    });
  },
  //
};
/** 区域管理 */
export const areaManageApi = {
  /** 编辑区域管理 */
  editDistrict: data => {
    return request({
      url: `/route/support/web/sys/region/editRegion`,
      data,
    });
  },
  /** 所有区域管理 */
  getDistrict: data => {
    return request({
      url: `/route/support/web/sys/region/queryRegion`,
      data,
    });
  },
};

/**
 * 立项管理 公桩立项管理 投建项目
 */
export const icProject = {
  // 投建项目列表
  list: data => {
    return request({
      url: '/route/support/web/project/getSupportProjectPage',
      data,
    });
  },
  // 投建项目修改
  edit: data => {
    return request({
      url: '/route/support/web/project/commitSupportProject',
      data,
    });
  },
  // 投建项目详情
  detail: data => {
    return request({
      url: '/route/support/web/project/supportProjectDetail',
      data,
    });
  },
  // 投建项目新建
  add: data => {
    return request({
      url: '/route/support/web/project/addSupportProject',
      data,
    });
  },
  // 投建项目删除
  delete: data => {
    return request({
      url: '/route/support/web/project/delSupportProject',
      data,
    });
  },
  // 投建项目单个信息
  getSupportProjectOne: params => {
    return request({
      method: 'GET',
      url: `/route/support/web/project/getSupportProjectOne`,
      params,
    });
  },
  // 计算投建建议数量
  getConstructionCount: data => {
    return request({
      url: '/route/support/web/project/getCalculateModel',
      data,
    });
  },
  // 查询物业下拉列表
  getPropertyListDown: params => {
    return request({
      method: 'GET',
      url: `/route/station/web/property/getPropertyListDown`,
      params,
    });
  },
};
/**
 * 私桩售桩
 */
export const salePrivatePileApi = {
  // 私桩-二维码新增
  add: data => {
    return request({
      url: '/route/order/web/orderPrivateCode/addPileQRCode',
      data,
    });
  },
  // 私桩-二维码删除
  del: data => {
    return request({
      url: '/route/order/web/orderPrivateCode/delPileQRCode',
      data,
    });
  },
  // 私桩-二维码编辑
  update: data => {
    return request({
      url: '/route/order/web/orderPrivateCode/updatePileQRCode',
      data,
    });
  },
  // 私桩-二维码列表
  list: data => {
    return request({
      url: '/route/order/web/orderPrivateCode/getListPage',
      data,
    });
  },
  // 私桩-建桩社区列表
  communityList: params => {
    return request({
      url: '/route/order/web/orderPrivateCode/communityPage',
      params,
      method: 'GET',
    });
  },
  // 私桩-生成二维码
  code: data => {
    return request({
      url: '/route/order/web/orderPrivateCode/generateQrCode',
      data,
    });
  },
  // 私桩-审核
  approval: data => {
    return request({
      url: '/route/order/web/orderPrivate/approvalPile',
      data,
    });
  },
  // 私桩-审核列表
  approvalList: data => {
    return request({
      url: '/route/order/web/orderPrivate/getApprovePage',
      data,
    });
  },
  // 私桩-派单
  dispatch: data => {
    return request({
      url: '/route/order/web/orderPrivate/dispatchStatus',
      data,
    });
  },
  // 私桩-工单列表
  workList: data => {
    return request({
      url: '/route/order/web/orderPrivate/getWorkPage',
      data,
    });
  },
  // 修改信息
  updatePileCode: data => {
    return request({
      url: '/route/order/web/orderPrivate/updatePileCode',
      data,
    });
  },
  // 删除审核列表
  deletedPrivatePile: data => {
    return request({
      url: '/route/order/web/orderPrivate/deletedPrivatePile',
      data,
    });
  },
};

/**
 * 私桩红包
 */
export const privateRedPackApi = {
  // 列表
  list: data => {
    return request({
      url: '/route/station/web/PrivatePile/redPackTemp/getRedTempPage',
      data,
    });
  },
  // 新增
  addOne: data => {
    return request({
      url: '/route/station/web/PrivatePile/redPackTemp/addRedPackTemp',
      data,
    });
  },
  // 删除
  delOne: data => {
    return request({
      url: '/route/station/web/PrivatePile/redPackTemp/deleteRedPackTemp',
      data,
    });
  },
};

/**
 * 红包记录
 */
export const redPackRecordApi = {
  // 记录列表
  list: data => {
    return request({
      url: '/route/station/web/PrivatePile/redPack/getPrivatePileRedPage',
      data,
    });
  },
  // 使用列表
  useList: data => {
    return request({
      url: '/route/finance/web/privatePile/redPack/getPileRedPackConsumeRecordPage',
      data,
    });
  },
  // 发放列表
  grantList: data => {
    return request({
      url: '/route/finance/web/privatePile/redPack/getPileRedPackRechargeRecordPage',
      data,
    });
  },
};
